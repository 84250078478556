import React from 'react';
import jobContent from '../../api/ContinuousRecruitment.json';
import JobAdvCard from './cards/JobAdvCard';
import { useTranslation } from 'react-i18next';

export default function JobAdv() {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className='w-full grid grid-cols-3 gap-4 bg-secondary-100 border border-secondary-200 rounded-[20px] p-4 '>
                <h2 className='col-span-3 text-tertiary text-xl my-4 font-bold'>
                    {t('AçıkPozisyonlar')}
                </h2>
                {jobContent.map((jobContent) => (
                    <JobAdvCard
                        title={t(`${jobContent.title}`)}
                        description={t(`${jobContent.description}`)}
                    />
                ))}
            </div>
        </>
    );
}
