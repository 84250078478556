import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SepetTasima() {
    const { t, i18n } = useTranslation();
    return (
        <section className='container pb-20 pt-10 flex flex-col items-center justify-center gap-y-8 text-center'>
            {/* Project Hero Image */}
            <div className='w-full xl:w-5/6 rounded-xl overflow-hidden'>
                <img
                    src='/projects/sepet-tasima/sepet-tasima.jpg'
                    className='aspect-[16/9] md:aspect-[19/9] object-cover'
                    alt='sepet-tasima'
                />
            </div>
            {/* Project Title & Description */}
            <div className='text-left xl:text-center'>
                <h1 className='text-4xl font-bold text-tertiary mb-4'>
                    {t('Sepet')}
                </h1>
                <p className='text-secondary-400'>{t('SepetD')}</p>
            </div>
            {/* Project Details */}
            <div className='grid grid-cols-4 gap-4 w-full'>
                <div className='col-span-4 md:col-span-2 bg-secondary-100 p-4 text-left rounded-lg'>
                    <h2 className='text-secondary-400'>{t('ProjeAmacı')}</h2>
                    <p className='text-tertiary'>{t('SepetA')}</p>
                </div>
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 p-4 text-left rounded-lg'>
                    <h2 className='text-secondary-400'>{t('ProjeSahibi')}</h2>
                    <p className='text-tertiary'>Ford Otosan</p>
                </div>
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 p-4 text-left rounded-lg'>
                    <h2 className='text-secondary-400'>{t('TeslimSüresi')}</h2>
                    <p className='text-tertiary'>{t('SepetZ')}</p>
                </div>
            </div>
            {/* Project Image Details */}
            <div className='grid grid-cols-3 gap-4 w-full'>
                <div className='col-span-3 lg:col-span-1 rounded-xl overflow-hidden'>
                    <img
                        src='/projects/sepet-tasima/sepet-tasima-2.jpg'
                        className='object-cover aspect-video'
                        alt='sepet-tasima-2'
                    />
                </div>
                <div className='col-span-3 lg:col-span-1 rounded-xl overflow-hidden'>
                    <img
                        src='/projects/sepet-tasima/sepet-tasima-3.jpg'
                        className='object-cover aspect-video'
                        alt='sepet-tasima-3'
                    />
                </div>
                <div className='col-span-3 lg:col-span-1 rounded-xl overflow-hidden'>
                    <img
                        src='/projects/sepet-tasima/sepet-tasima-4.jpg'
                        className='object-cover aspect-video'
                        alt='sepet-tasima-4'
                    />
                </div>
            </div>
        </section>
    );
}
