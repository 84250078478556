import React from 'react';
import MissionAndVisionCard from '../components/AboutUs/card/MissionAndVisionCard';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { useTranslation } from 'react-i18next';

export default function Corporate() {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <section className='container py-20 flex flex-col items-center justify-start gap-y-14 text-center w-screen'>
                {/* Who Are We*/}
                <div className='flex flex-col'>
                    <h1 className='text-tertiary text-4xl font-bold mb-2'>
                        {t('Biz Kimiz?')}
                    </h1>
                    <p className='text-secondary-400 w-full md:w-2/3 m-auto'>
                        {t('Biz Kimiz Açıklama')}
                    </p>
                </div>
                {/* Mission and Vision */}
                <div className='grid grid-cols-2 w-full gap-4'>
                    <MissionAndVisionCard
                        icon='/icons/mission.svg'
                        title='Misyonumuz'
                        description='MisyonAçıklama'
                    ></MissionAndVisionCard>
                    <MissionAndVisionCard
                        icon='/icons/vision.svg'
                        title='Vizyonumuz'
                        description='VizyonAçıklama'
                    ></MissionAndVisionCard>
                </div>
                <div>
                    <h2 className='text-tertiary text-4xl font-bold mb-6'>
                        {t('Sertifika')}
                    </h2>
                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
                        <button
                            type='button'
                            onClick={() => setOpen(true)}
                            className='group'
                        >
                            <div className='bg-secondary p-4 border-2 border-secondary-200 rounded-xl col-span-1'>
                                <img
                                    src='./certificates/iso-9001-2015.jpg'
                                    className='aspect-video object-cover rounded-lg group-hover:object-top transition-all duration-1000 delay-75'
                                />
                                <h3 className='mt-3 text-tertiary font-bold text-xl'>
                                    ISO 9001:2015
                                </h3>
                            </div>
                        </button>
                        <button
                            type='button'
                            onClick={() => setOpen(true)}
                            className='group'
                        >
                            <div className='bg-secondary p-4 border-2 border-secondary-200 rounded-xl col-span-1'>
                                <img
                                    src='./certificates/iso-14001-2015.jpg'
                                    className='aspect-video object-cover rounded-lg group-hover:object-top transition-all duration-1000 delay-75'
                                />
                                <h3 className='mt-3 text-tertiary font-bold text-xl'>
                                    ISO 9001:2015
                                </h3>
                            </div>
                        </button>
                        <button
                            type='button'
                            onClick={() => setOpen(true)}
                            className='group'
                        >
                            <div className='bg-secondary p-4 border-2 border-secondary-200 rounded-xl col-span-1'>
                                <img
                                    src='./certificates/iso-45001-2018.jpg'
                                    className='aspect-video object-cover rounded-lg group-hover:object-top transition-all duration-1000 delay-75'
                                />
                                <h3 className='mt-3 text-tertiary font-bold text-xl'>
                                    ISO 9001:2015
                                </h3>
                            </div>
                        </button>
                    </div>
                </div>
                <Lightbox
                    open={open}
                    close={() => setOpen(false)}
                    slides={[
                        { src: './certificates/iso-9001-2015.jpg' },
                        { src: './certificates/iso-14001-2015.jpg' },
                        { src: './certificates/iso-45001-2018.jpg' },
                    ]}
                />
            </section>
        </>
    );
}
