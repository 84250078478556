import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../components/FormElements/TextInput';
import BasicInput from '../components/FormElements/BasicInput';
import ButtonPrimary from '../components/FormElements/ButtonPrimary';
import emailjs from '@emailjs/browser';

function Contact() {
    const { t, i18n } = useTranslation();

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        const submitBtn = document.getElementById('submit');
        const fullname = document.getElementById('fullname');
        const email = document.getElementById('email');
        const title = document.getElementById('title');
        const description = document.getElementById('description');
        const succes = document.getElementById('succes');
        const failed = document.getElementById('failed');
        const modalWrapper = document.getElementById('modal-wrapper');
        submitBtn.classList.remove(
            'hover:bg-primary-darken',
            'bg-primary',
            'cursor-pointer',
            'text-white'
        );
        submitBtn.classList.add(
            'bg-white',
            'cursor-not-allowed',
            'text-secondary'
        );
        submitBtn.innerHTML = `${t('Gönderiliyor')}`;

        emailjs
            .sendForm(
                'service_vujz1wk',
                'template_tl4va25',
                form.current,
                'lwJGsAE6grZt3-cPf'
            )
            .then(
                (result) => {
                    modalWrapper.classList.remove('hidden');
                    succes.classList.remove('hidden');
                    submitBtn.classList.add(
                        'hover:bg-primary-darken',
                        'bg-primary',
                        'cursor-pointer',
                        'text-white'
                    );
                    submitBtn.classList.remove(
                        'bg-white',
                        'cursor-not-allowed',
                        'text-secondary'
                    );
                    submitBtn.innerHTML = `${t('Submit')}`;
                    fullname.value = '';
                    email.value = '';
                    title.value = '';
                    description.value = '';
                },

                (error) => {
                    modalWrapper.classList.remove('hidden');
                    failed.classList.remove('hidden');
                    submitBtn.classList.add(
                        'hover:bg-primary-darken',
                        'bg-primary',
                        'cursor-pointer',
                        'text-white'
                    );
                    submitBtn.classList.remove(
                        'bg-white',
                        'cursor-not-allowed',
                        'text-secondary'
                    );
                    submitBtn.innerHTML = `${t('Submit')}`;
                }
            );
    };

    const closeSuccesModal = () => {
        const modalWrapper = document.getElementById('modal-wrapper');
        modalWrapper.classList.add('hidden');
    };

    return (
        <>
            <section className='container min-h-screen pt-20 flex flex-col items-center justify-start gap-y-8 text-center'>
                <div>
                    <h1 className='text-tertiary text-4xl font-bold mb-2'>
                        {t('İLB')}
                    </h1>
                    <p className='text-secondary-400'>{t('İLA')}</p>
                </div>
                {/* Forms */}
                <div
                    id='contactForm'
                    className='bg-secondary-100 border border-secondary-200 p-6 rounded-xl w-full'
                >
                    <form
                        className=' grid grid-cols-1 lg:grid-cols-3 md:gap-x-6 gap-y-6 w-full items-start'
                        ref={form}
                        onSubmit={sendEmail}
                    >
                        <div className='text-left col-span-3 lg:col-span-1'>
                            <TextInput
                                id='fullname'
                                for='fullname'
                                name='fullname'
                                label={t('AdSoyad')}
                                placeholder={t('AdPH')}
                            />
                        </div>
                        <div className='text-left col-span-3 lg:col-span-1'>
                            <BasicInput
                                id='email'
                                for='email'
                                name='email'
                                label={t('Email')}
                                placeholder={t('EmailPH')}
                            />
                        </div>
                        <div className='text-left col-span-3 lg:col-span-1'>
                            <BasicInput
                                id='title'
                                for='title'
                                name='title'
                                label={t('Konu')}
                                placeholder={t('KonuPH')}
                            />
                        </div>
                        <div className='text-left col-span-3'>
                            <label
                                for='description'
                                className='text-white font-semibold'
                            >
                                {t('Açıklama')}
                            </label>
                            <textarea
                                id='description'
                                placeholder={t('Mesaj')}
                                name='description'
                                className='text-tertiary mt-2 px-2 py-4 w-full bg-secondary focus:bg-secondary-200 border border-secondary-200 hover:border-primary/60 focus:border-primary active:border-primary focus:outline-0 focus:ring-0 active:outline-0 active:ring-0 rounded-md min-h-[100px] h-[100px] max-h-[160px] placeholder-secondary-300'
                            ></textarea>
                        </div>
                        <div className='col-span-3 lg:col-span-1'>
                            <ButtonPrimary
                                title={t('Submit')}
                                type='submit'
                                id='submit'
                            />
                        </div>
                    </form>
                </div>
            </section>
            {/* Modal */}
            <div
                className='absolute w-screen h-screen z-50 bg-black/75 top-0 left-0 hidden'
                id='modal-wrapper'
            >
                {/* Başarılı */}
                <div
                    className='hidden bg-tertiary w-4/5 md:w-1/2 absolute top-1/2 left-1/2 z-[51] -translate-y-1/2 -translate-x-1/2 rounded-xl py-4 px-8'
                    id='succes'
                >
                    <button
                        className='block ml-auto font-bold mb-4 border-2 border-secondary p-2 rounded-full group hover:bg-secondary transition-all duration-500'
                        id='succes-close'
                        onClick={closeSuccesModal}
                    >
                        <svg
                            width='11'
                            height='10'
                            viewBox='0 0 11 10'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='text-secondary group-hover:text-tertiary transition-all duration-500'
                        >
                            <path
                                d='M9.49998 8.99998L1.5 1M9.50002 1L1.5 9.00002'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                            />
                        </svg>
                    </button>
                    <h1 className='font-bold text-2xl text-secondary mb-2'>
                        {t('MesajınızıAldık')} &#128077;
                    </h1>
                    <p className='text-secondary-300 leading-tight mb-2'>
                        {t('MesajınızıAldıkAçıklama')}
                    </p>
                </div>
                <div
                    className='hidden bg-tertiary w-4/5 md:w-1/2 absolute top-1/2 left-1/2 z-[51] -translate-y-1/2 -translate-x-1/2 rounded-xl py-4 px-8'
                    id='failed'
                >
                    <button
                        className='block ml-auto font-bold mb-4 border-2 border-secondary p-2 rounded-full group hover:bg-secondary transition-all duration-500'
                        id='succes-close'
                        onClick={closeSuccesModal}
                    >
                        <svg
                            width='11'
                            height='10'
                            viewBox='0 0 11 10'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='text-secondary group-hover:text-tertiary transition-all duration-500'
                        >
                            <path
                                d='M9.49998 8.99998L1.5 1M9.50002 1L1.5 9.00002'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                            />
                        </svg>
                    </button>
                    <h1 className='font-bold text-2xl text-secondary mb-2'>
                        {t('MesajınızıAlamadık')} &#128532;
                    </h1>
                    <p className='text-secondary-300 leading-tight mb-2'>
                        {t('MesajınızıAlamadıkAçıklama')}
                    </p>
                </div>
            </div>
        </>
    );
}

export default Contact;
