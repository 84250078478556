import React, { useEffect } from 'react';
import machineContent from '../../api/OurMachines.json';
import OurMachineRow from './card/OurMachineRow';
import { useTranslation } from 'react-i18next';

export default function OurMachines() {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        let imageContainer = document.getElementsByClassName('img-container');
        let machineContent = document.getElementsByClassName('machine-content');
        let childItem1 = imageContainer.item(1);
        let machineChildItem1 = machineContent.item(1);
        let childItem2 = imageContainer.item(3);
        let machineChildItem2 = machineContent.item(3);
        childItem1.classList.remove(
            'md:order-last',
            'md:-ml-20',
            'md:before:bg-gradient-to-r'
        );
        childItem1.classList.add('md:-mr-20', 'md:before:bg-gradient-to-l');
        machineChildItem1.classList.remove('md:text-right');
        machineChildItem1.classList.add('md:text-left');
        childItem2.classList.remove(
            'md:order-last',
            'md:-ml-20',
            'md:before:bg-gradient-to-r'
        );
        childItem2.classList.add('md:-mr-20', 'md:before:bg-gradient-to-l');
        machineChildItem2.classList.remove('md:text-right');
        machineChildItem2.classList.add('md:text-left');
    });
    return (
        <section className='container pt-20 flex flex-col items-center justify-center gap-y-4 leading-tight'>
            <h2 className='text-white font-bold text-xl'>
                {t('MakineParkuru')}
            </h2>
            <div className='w-full flex flex-col gap-y-6 md:gap-y-4'>
                {machineContent.map((machineContent) => (
                    <OurMachineRow
                        image={machineContent.image}
                        title={t(`${machineContent.title}`)}
                        li1={t(`${machineContent.li1}`)}
                        li2={t(`${machineContent.li2}`)}
                    />
                ))}
            </div>
        </section>
    );
}
