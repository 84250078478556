import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Manufacturing() {
    const { t, i18n } = useTranslation();
    return (
        <section className='container min-h-screen py-20 flex flex-col items-center justify-start gap-y-8'>
            <div className='text-center'>
                <h1 className='text-tertiary text-4xl font-bold mb-2'>
                    {t('İBaşlık')}
                </h1>
                <p className='text-secondary-400'>{t('İAçıklama')}</p>
                <Link
                    to='/iletisim'
                    className='inline-flex items-center justify-center mt-8 gap-x-3 group'
                >
                    <span className='text-white font-semibold group-hover:text-primary '>
                        {t('Birlikte Çalışalım')}
                    </span>
                    <span>
                        <svg
                            width='8'
                            height='13'
                            viewBox='0 0 8 13'
                            fill='currentColor'
                            xmlns='http://www.w3.org/2000/svg'
                            className='group-hover:text-primary text-white'
                        >
                            <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M7.40001 6.50001C7.40001 6.76523 7.29465 7.01958 7.10712 7.20712L2.30712 12.0071C1.91659 12.3976 1.28343 12.3976 0.892902 12.0071C0.502378 11.6166 0.502378 10.9834 0.892902 10.5929L4.9858 6.50001L0.892903 2.40712C0.502378 2.01659 0.502378 1.38343 0.892903 0.992905C1.28343 0.602381 1.91659 0.602381 2.30712 0.992905L7.10712 5.79291C7.29465 5.98044 7.40001 6.2348 7.40001 6.50001Z'
                                fill='currentColor'
                            />
                        </svg>
                    </span>
                </Link>
            </div>
            <div className='grid grid-cols-4 w-full gap-4 relative'>
                {/* Özel Makine İmalatları */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 rounded-xl overflow-hidden border border-secondary-200 flex flex-col justify-between'>
                    <div>
                        <img
                            src='/projects/tagrak/tagrak.jpg'
                            className='aspect-video object-cover'
                            alt='tagrak'
                        />
                        <div className='p-6'>
                            <h2 className='font-bold text-xl mb-2 text-tertiary'>
                                {t('MakineB')}
                            </h2>
                            <p className='text-secondary-400 mb-2'>
                                {t('MakineA')}
                            </p>
                            <ul className='list-disc pl-4 text-secondary-400'>
                                <li>{t('MakineL1')}</li>
                                <li>{t('MakineL2')}</li>
                                <li>{t('MakineL3')}</li>
                                <li>{t('MakineL4')}</li>
                                <li>{t('MakineL5')}</li>
                                <li>{t('MakineL6')}</li>
                                <li>{t('MakineL7')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='text-right p-6'>
                        <Link
                            to='/iletisim'
                            className='bg-transparent py-1 px-4 border-2 border-primary rounded-lg text-primary font-bold hover:text-tertiary hover:bg-primary'
                        >
                            {t('TeklifAlButon')}
                        </Link>
                    </div>
                </div>
                {/* Özel Konveyör ve Üretim Hatları İmalatları */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 rounded-xl overflow-hidden border border-secondary-200 flex flex-col justify-between'>
                    <div>
                        <img
                            src='/services/konveyor.jpg'
                            className='aspect-video object-cover'
                            alt='konveyor'
                        />
                        <div className='p-6'>
                            <h2 className='font-bold text-xl mb-2 text-tertiary'>
                                {t('KonveyörB')}
                            </h2>
                            <p className='text-secondary-400 mb-2'>
                                {t('KonveyörA')}
                            </p>
                            <ul className='list-disc pl-4 text-secondary-400'>
                                <li>{t('KonveyörL1')}</li>
                                <li>{t('KonveyörL2')}</li>
                                <li>{t('KonveyörL3')}</li>
                                <li>{t('KonveyörL4')}</li>
                                <li>{t('KonveyörL5')}</li>
                                <li>{t('KonveyörL6')}</li>
                                <li>{t('KonveyörL7')}</li>
                                <li>{t('KonveyörL8')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='text-right p-6'>
                        <Link
                            to='/iletisim'
                            className='bg-transparent py-1 px-4 border-2 border-primary rounded-lg text-primary font-bold hover:text-tertiary hover:bg-primary'
                        >
                            {t('TeklifAlButon')}
                        </Link>
                    </div>
                </div>
                {/* Fikstür, Manipülatör ve Aparat İmalatları */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 rounded-xl overflow-hidden border border-secondary-200 flex flex-col justify-between'>
                    <div>
                        <img
                            src='/services/fikstur.jpg'
                            className='aspect-video object-cover'
                            alt='fikstur'
                        />
                        <div className='p-6'>
                            <h2 className='font-bold text-xl mb-2 text-tertiary'>
                                {t('FikstürB')}
                            </h2>
                            <p className='text-secondary-400 mb-2'>
                                {t('FikstürA')}
                            </p>
                            <ul className='list-disc pl-4 text-secondary-400'>
                                <li>{t('FikstürL1')}</li>
                                <li>{t('FikstürL2')}</li>
                                <li>{t('FikstürL3')}</li>
                                <li>{t('FikstürL4')}</li>
                                <li>{t('FikstürL5')}</li>
                                <li>{t('FikstürL6')}</li>
                                <li>{t('FikstürL7')}</li>
                                <li>{t('FikstürL8')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='text-right p-6'>
                        <Link
                            to='/iletisim'
                            className='bg-transparent py-1 px-4 border-2 border-primary rounded-lg text-primary font-bold hover:text-tertiary hover:bg-primary'
                        >
                            {t('TeklifAlButon')}
                        </Link>
                    </div>
                </div>
                {/* Talaşlı İmalat Hizmetleri */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 rounded-xl overflow-hidden border border-secondary-200 flex flex-col justify-between'>
                    <div>
                        <img
                            src='/services/talasli-imalat.jpg'
                            className='aspect-video object-cover'
                        />
                        <div className='p-6'>
                            <h2 className='font-bold text-xl mb-2 text-tertiary'>
                                {t('TalaşlıB')}
                            </h2>
                            <p className='text-secondary-400 mb-2'>
                                {t('TalaşlıA')}
                            </p>
                            <ul className='list-disc pl-4 text-secondary-400'>
                                <li>{t('TalaşlıL1')}</li>
                                <li>{t('TalaşlıL2')}</li>
                                <li>{t('TalaşlıL3')}</li>
                                <li>{t('TalaşlıL4')}</li>
                                <li>{t('TalaşlıL5')}</li>
                                <li>{t('TalaşlıL6')}</li>
                                <li>{t('TalaşlıL7')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='text-right p-6'>
                        <Link
                            to='/iletisim'
                            className='bg-transparent py-1 px-4 border-2 border-primary rounded-lg text-primary font-bold hover:text-tertiary hover:bg-primary'
                        >
                            {t('TeklifAlButon')}
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
