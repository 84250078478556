import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    tr: {
        translation: {
            Language: 'TR',
            'Insmech Size': 'InsMech Size',
            'Hizmeti Verir': 'Hizmeti Verir',
            'Proses İyileştirmeleri': 'Proses İyileştirmeleri',
            'Fikstür İmalatı': 'Fikstür İmalatı',
            'Makine İmalatı': 'Makine İmalatı',
            AçıklamaHero:
                'Üretim prosesinizde ihtiyacınız olan özel makine, mühendislik hizmetleri, sistem ortaklaştırma, manipülatör, fikstür, konveyör projelerinizi anahtar teslim yapmaktadır. Proje içerisindeki dizayn, otomasyon yazılımları ve devreye alma, bünyemizdeki mühendislik ekibi tarafından yapılmaktadır. Fiyat avantajımız ve yüksek kalitemizle sizlerle iş birliği yapmak için sabırsızlanıyoruz.',
            'iletişim-btn': 'İletişime Geç',
            'Biz Kimiz?': 'BİZ KİMİZ?',
            'Biz Kimiz Açıklama':
                'InsMech Mühendislik, makine sektöründeki tecrübesi ve nitelikli mühendislik altyapısı ile Türk sanayisinde, ithal etmek yerine imal edebilen firmalardan biri olma amacıyla kurulmuştur. Çok kısa sürede otomotiv ve beyaz eşya sanayisinin devlerine çözüm ortaklığı yapmayı başarmış; gelişen teknoloji ile birlikte imalat tekniğini ve mühendislik yeteneklerini her geçen gün geliştirerek yoluna devam etmektedir.',
            'Birlikte Çalışalım': 'Birlikte Çalışalım',
            Uzmanlıklarımız: 'Uzmanlıklarımız',
            Uzmanlık1Başlık: 'Anahtar Teslim Projeler',
            Uzmanlık1Açıklama:
                'Prosesinizin tasarımından imalatına elektrik pano devreye almasına kadarki süreci deneyimli mühendislik ekibimiz tarafından yapılmaktadır. Tüm süreçler sizlerin onayına istinaden devam ettirilir.',
            Uzmanlık2Başlık: 'Mühendislik Hizmetleri',
            Uzmanlık2Açıklama:
                'Projeye ait 3D tasarımın oluşturularak, analiz sonuçlarıyla birlikte projelendirme süreç yönetimi yapılır.',
            Uzmanlık3Başlık: 'Pano İmalatları/Otomasyon',
            Uzmanlık3Açıklama:
                'Arıza takibi yapılamayan, sürekli arıza veren panoların son teknoloji sistemlerle endüstri 4.0 kalitesinde Siemens PLC ağırlıklı olarak imalatı yapılmaktadır.',
            Uzmanlık4Başlık: 'Kalıp İmalatları',
            Uzmanlık4Açıklama: 'Parçalarınıza ait kalıpların imalatları.',
            AnaSayfa: 'Ana Sayfa',
            Kurumsal: 'Kurumsal',
            Hizmetlerimiz: 'Hizmetlerimiz',
            EndüstriyelHizmetler: 'Endüstriyel Hizmetler',
            MühendislikHizmetleri: 'Mühendislik Hizmetleri',
            İmalatlar: 'İmalatlar',
            Projelerimiz: 'Projelerimiz',
            Referanslarımız: 'Referanslarımız',
            Kariyer: 'Kariyer',
            İletişim: 'İletişim',
            MakineParkuru: 'MAKİNE PARKURUMUZ',
            Makine1Başlık: 'CNC Freze',
            Makine1li1: '600x1500x650mm',
            Makine1li2: '2000x1000x600mm',
            Makine2Başlık: 'Universal Freze',
            Makine2li1: '4 Numara (2 Adet)',
            Makine2li2: '',
            Makine3Başlık: 'Universal Torna',
            Makine3li1: 'Çap 500/Boy 2000',
            Makine3li2: 'Çap 300/Boy 1000',
            Makine4Başlık: 'Kaynak Makineleri',
            Makine4li1: '500A (3 Adet)',
            Makine4li2: '400A (3 Adet)',
            Makine5Başlık: 'Şerit Testere',
            Makine5li1: 'KM350',
            Makine5li2: '',
            NavFooter: 'NAVİGASYON',
            HizmetlerimizFooter: 'HİZMETLERİMİZ',
            BizeDairFooter: 'BİZE DAİR',
            İletişimFooter: 'İLETİŞİM',
            EkibimizeKatıl: 'Ekibimize Katıl',
            EKatalog: 'E-Katalog İndir',
            Adres: 'Üçevler Mahallesi Beşevler Sanayi Sitesi 26. Sokak 39. Blok No: 80 Nilüfer/Bursa',
            MailGönder: 'info@insmech.com',
            TeklifAlİ: 'Teklif Al',
            Türkçe: 'Türkçe',
            İngilizce: 'İngilizce',
            Copyright: '© 2023 InsMech. Tüm Hakları Saklıdır.',
            CreatedBy: '',
            Tarafından: 'tarafından hazırlandı.',
            Nokta: '',
            Misyonumuz: 'Misyonumuz',
            MisyonAçıklama:
                'Tüm imalat sektörlerine makine imalat ve revizyon, iş iyileştirme, iş geliştirme ve hat iyileştirmelerinde hizmet verebilmek. Özel tasarımlar ile talep edilen her makine, parça, aparat, fikstür ve yazılım hizmetlerine cevap verebilmek.',
            Vizyonumuz: 'Vizyonumuz',
            VizyonAçıklama:
                'Hızla gelişmekte olan teknoloji ile aynı paralelde kendimizi de geliştirmek, her geçen gün büyüyen ve gelişen yapımız ile teknolojiye yön veren firmalar arasına katılmak. Yurt dışı proje sayımızı arttırarak ülkemizi en iyi şekilde temsil etmek.',
            Sertifika: 'SERTİFİKALARIMIZ',
            KariyerBaşlık: 'KARİYER PLANINI BİZİMLE ÇİZMEK İSTER MİSİN?',
            KariyerAçıklama:
                'Türk sanayisinde, ithal etmek yerine imal edebilen firmalardan biri olma amacıyla kurulmuş olan InsMech Mühendislik olarak, misyonlarımızı ve vizyonlarımızı paylaşabileceğimiz ekip arkadaşlarımızın aramıza katılımından mutluluk duyarız.',
            ReferansBaşlık: 'REFERANSLARIMIZ',
            ReferansAçıklama:
                'İş birliği içerisinde bulunduğu her markanın değerlerinin farkında olarak hareket eden, kaliteyi en üst düzeyde tutarken maliyeti uygun düzeylerde tutmayı başaran ve süreçleri, yüzler gülmeden noktalandırmayan InsMech, yeni iş birliklerine her zaman açık!',
            Ref: 'REFERANSLARIMIZ',
            Referansİnceleme: 'Bütün Referanslarımızı İnceleyin',
            ProjelerimizBaşlık: 'PROJELERİMİZ',
            ProjelerimizAçıklama:
                'Üretim süreçlerinizde özel makinelerden mühendislik hizmetlerine, sistem ortaklaştırmadan manipülatörlere, fikstürlerden konveyör projelerine kadar ihtiyaç duyduğunuz her şeyi anahtar teslim sunuyoruz. Müşterilerimize sunduğumuz rekabetçi fiyat avantajları ve üstün kaliteyle iş birliği yapmak için sabırsızlanıyoruz. Şirketinizin hedeflerini gerçekleştirmek ve başarıya ulaşmak için uzmanlığımızı sizinle paylaşmaktan mutluluk duyarız. Bize katılın ve işinizin büyümesine yönelik adımlar atmaya başlayalım.',
            Kaynakçı: 'Kaynakçı',
            KaynakçıAçıklama:
                'Yüksek kalite standartlarına uygun kaynak işlemlerini gerçekleştiren deneyimli kaynakçılar arıyoruz. Sorumluluk sahibi ve ekip çalışmasına yatkın olanlar, kariyerlerini geliştirmek için şimdi başvurabilirler. Projelerimizde önemli bir rol oynamak için hemen bize katılın!',
            Tornacı: 'Tornacı',
            TornacıAçıklama:
                'Parça işleme, kalite standartlarına uygun tamamlama ve takım çalışmasına yatkınlık gibi becerilere sahip tecrübeli tornacı arıyoruz. Başarı hedeflerimize katkıda bulunmak, kariyerinizi geliştirmek için sertifikalarınızı ve deneyimlerinizi bizimle paylaşın. Mühendislik projelerinde önemli bir rol oynamak için ekibimize katılın!',
            Montajcı: 'Makine/Mekanik Montajcısı',
            MontajcıAçıklama:
                'Mekanik bileşenleri monte edecek, makine sistemlerini kuracak ve projelerin hatasız tamamlanmasını sağlayacak deneyimli Makine/Mekanik Montajcısı arıyoruz. Talimatları takip ederek kalite standartlarına uygun çalışacak ve takım içinde sorunları çözeceksiniz. Deneyimlerinizi bizimle paylaşarak, mühendislik projelerinde etkili bir rol oynayabilir ve kariyerinizi geliştirebilirsiniz. Başarı yolculuğumuza katılmanızı bekliyoruz!',
            Bakımcı: 'Makine Bakımcısı',
            BakımcıAçıklama:
                'Makine ve ekipmanların bakım, onarım ve arıza tespiti konularında yetenekli olan deneyimli Makine Bakımcısı arıyoruz. Güvenlik standartlarına uygun çalışarak şirketimizin operasyonlarına katkı sağlayacak ve mühendislik projelerinde önemli bir rol oynayabileceksiniz. Deneyimlerinizi ve sertifikalarınızı bizimle paylaşarak kariyerinizi geliştirin. Hemen başvurun!',
            AçıkPozisyonlar: 'Açık Pozisyonlar',
            İşBaşvuruForm: 'İş Başvuru Formu',
            FormBaşlık: "CV'nizi yükleyin, kolayca başvurun!",
            FormAçıklama:
                "İlk adım olarak ayrılan alana '.pdf' uzantılı CV'nizi yükleyin. Sonrasında aşağıda yer alan formu eksiksiz doldurarak 'Başvuruyu Gönder' butonuna tıkladığınız takdirde başvurunuz tarafımıza ulaşacak ve sizlere geri dönüş sağlayacağız.",
            CVBaşlık: "Buraya tıklayarak CV'nizi yükleyebilirsiniz.",
            CVAçıklama: '*Yalnızca PDF uzantılı belgeleri yükleyebilirsiniz.',
            CVDurum1: 'Dosya Seçilmedi',
            CVDurum2: 'Eklediğiniz dosya adı:',
            AdSoyad: 'Adınız ve Soyadınız',
            AdPH: 'InsMech Mühendislik',
            Email: 'Email Adresiniz',
            EmailPH: 'info@insmech.com',
            Telefon: 'Telefon Numaranız',
            TelefonPH: '0 (224) 441 30 02',
            Şehir: "Bursa'da mı Yaşıyorsunuz?",
            İlçe: 'Hangi İlçede Yaşıyorsunuz?',
            İş: 'Şu Anda Bir İşte Çalışıyor Musunuz?',
            Başlangıç: 'İşe Ne Zaman Başlayabilirsiniz?',
            Askerlik: 'Askerliğinizi Yaptınız mı?',
            Sabıka: 'Sabıka Kaydınız Var mı?',
            Tecrübe: 'İş Tecrübeniz',
            Eğitim: 'Eğitim Durumunuz',
            Maaş: 'Maaş Beklentiniz Nedir?',
            Not: 'Bize Bırakmak İstediğiniz Bir Not Var mı?',
            NotPH: 'Mesajınızı buraya bırakın',
            Seçim: 'Lütfen bir seçim yapınız',
            Evet: 'Evet',
            Hayır: 'Hayır',
            Hemen: 'Hemen',
            '1-2Hafta': '1-2 hafta içerisinde',
            '3-4Hafta': '3-4 hafta içerisinde',
            '0-1': '0-1 yıl',
            '1-3': '1-3 yıl',
            '3-5': '3-5 yıl',
            '5YıldanFazla': '5 yıldan fazla',
            İlköğretim: 'İlköğretim',
            Lise: 'Lise',
            Lisans: 'Lisans',
            YüksekLisans: 'Yüksek Lisans',
            Doktora: 'Doktora',
            MaaşDetay: '17.500₺ ve yukarısı',
            TeklifAl: 'TEKLİF AL',
            İletişimeGeç: 'İLETİŞİME GEÇ',
            Bizimleİletişim: 'Bizimle İletişime Geç!',
            BizimleİletişimAçıklama:
                'Bizimle iletişime geçerek bizden teklif alabilir ya da aklınıza takılanları sorabilirsiniz.',
            Konu: 'Konu Başlığı',
            KonuPH: 'InsMech Hk.',
            Açıklama: 'Açıklama',
            Mesaj: 'Mesajınızı girin',
            TeklifDosya:
                'Buraya tıklayarak teklifinizle ilişkili (varsa) dosya/dosyaları ekleyebilirsiniz.',
            Submit: 'Gönder',
            İşBaşvuru: 'İş başvurusu yapmak için',
            İşBaşvuruLink: 'buraya tıklayınız.',
            İşBaşvuruDevam: '',
            BaşvuruGönder: 'Başvuruyu Gönder',
            MHBaşlık: 'MÜHENDİSLİK HİZMETLERİ',
            MHAçıklama:
                'Uzman mühendislik hizmetleri sunarak markanızın başarısına katkıda bulunuyoruz. İş birliği yaparak, projelerinizdeki potansiyeli en üst düzeye çıkarabilir, verimliliği artırabilir ve maliyetleri optimize edebiliriz. Teknolojiye dayalı yenilikçi çözümlerimizle sizinle birlikte büyümek için buradayız.',
            EHBaşlık: 'ENDÜSTRİYEL HİZMETLER',
            EHAçıklama:
                'Endüstriyel hizmetlerimizle işletmenizi güçlendirin! Deneyimli ekibimiz, kalite, verimlilik ve sürdürülebilirlik odaklı çözümler sunarak operasyonlarınızı optimize eder. Birlikte çalışarak, rekabet avantajınızı artırabilir ve endüstriyel projelerinizde başarıya ulaşabiliriz. Markanızı büyütmek ve endüstriyel hedeflerinize ulaşmak için birlikte çalışalım!',
            İBaşlık: 'İMALATLAR',
            İAçıklama:
                'İmalat alanında uzmanlaşmış ekibimizle sizin için en iyi çözümleri üretiyoruz. Müşteri odaklı yaklaşımımızla, kalite standartlarını karşılayan ve zamana uygun üretim sağlıyoruz. İmalat ihtiyaçlarınızı karşılamak ve ürünlerinizi pazara hızla sunmak için iş birliği yapmaya hazırız.',
            TasarımB: 'Tasarım',
            TasarımA:
                'InsMech olarak sorunlarınıza çözüm bulmak için buradayız. 10 bin saatlik tasarım kapasitemiz ile makine, konveyör, fikstür ve aparat tasarımlarında, makinelerinizin ve hatlarınızın iyileştirilmesinde sizlere yardımcı olmaya hazırız. Tek yapmanız gereken bize ulaşmak.',
            Ölçülendirme: 'Ölçülendirme',
            '3DÖlçüm': '3D ölçüm',
            Simülasyon: 'Çalışma simülasyonları',
            Render: 'Sunumlarınız için 4K render görüntüleri',
            İyileştirme: 'Mevcut tasarımların iyileştirilmesi',
            AnalizB: 'Analiz',
            AnalizA:
                'InsMech üretimlerinin her alanında hizmetinizde. Tecrübeli mühendis kadromuz ile analiz, ölçüm ve test hizmetlerimizle üretiminize fark ve kalite katmaya devam ediyoruz. Verdiğimiz bazı hizmetlerimiz:',
            AnalizL1: 'Statik analiz',
            AnalizL2: 'Dinamik analiz',
            AnalizL3: 'Kaynak testleri',
            AnalizL4: '3D çatlak çözüm',
            AnalizL5: 'Yorulma testleri',
            OtomasyonB: 'Otomasyon',
            OtomasyonA:
                'Tecrübeli yazılım ve elektrik ekibimizle verdiğimiz bazı otomasyon hizmetlerimiz:',
            OtomasyonL1: 'Pano revizyonları',
            OtomasyonL2: 'Pano imalatları ve devreye alma',
            OtomasyonL3: 'PLC yazılım ve devreye alma',
            OtomasyonL4: 'Makine elektrik ve yazılım revizyonları',
            OtomasyonL5: 'Pres elektrik ve yazılım revizyonları',
            OtomasyonL6: 'Makine kurulum ve devreye alma',
            KaizenB: 'Kaizen/İş Güvenliği',
            KaizenA:
                'InsMech olarak makine performans, çevrim süresi iyileştirmelerinde, makine iş ve işçi güvenliği çalışmalarınızda, çalışan ergonomisi çalışmalarınızda hizmetlerimizle yanınızdayız. Sorunlarınızı bize anlatın ve gerisini bize bırakın. InsMech ile üretiminiz daha güvenli, veriminiz daha yüksek olacak.',
            İyileştirmeB: 'Makine İyileştirmeleri',
            İyileştirmeA:
                'Makine revizyonları, çevrim hızlandırma, ekipman yenileme, makine iş sağlığı ve güveliği (isg) revizyonları, makine transfer ve besleme sistemleri revizyon ve yeniden imalatlarında InsMech olarak tecrübeli mühendislik ekibimiz ile birlikte hizmet vermekteyiz.',
            TaşımaB: 'Taşıma/Kurulum',
            TaşımaA:
                'Proseslerin büyütülmesi için alınan makinelerin/hatların kurulumlarında ve transferinde:',
            TaşımaL1:
                'Çalışır durumda makinelerin/proseslerin demontajının gerçekleştirilmesi',
            TaşımaL2:
                'Yükleme yöntemlerinin tespiti ve yüklemenin organizasyonun yapılması',
            TaşımaL3: 'Nakliye işlemlerinin birbiri içinde koordinasyonu',
            TaşımaL4: 'Kurulacak alan kontrolleri ve zeminin hazırlanması',
            TaşımaL5: 'İhtiyacınız olabilecek zemin etrafının kapatılması',
            TaşımaL6: 'Proses besleme enerjilerinin çekilmesi',
            TaşımaL7: 'Proses besleme hava ve su hatlarının devreye alınması',
            TaşımaL8:
                'Proses için gerekli olabilecek soğutma sistemlerinin projelendirilmesi',
            TaşımaL9: 'İhtiyaç halinde elektrik pano revizyonları',
            ProsesB: 'Proses İyileştirmeleri',
            ProsesA:
                'InsMech olarak makine performans, çevrim süresi iyileştirmelerinde, hat iyileştirmelerinde, makine-hat transfer ve beslemelerinin iyileştirme ve imalatlarında hizmet vermekteyiz.',
            PlatformB: 'Platform ve Boru Hatları',
            PlatformA:
                'InsMech olarak çelik konstrüksiyon hizmetlerimizle fabrika alanınızı daha verimli kullanmanızı sağlıyoruz.',
            PlatformL1: 'Platform imalatları',
            PlatformL2: 'Hidrolik & Pnomatik hareketli platformlar',
            PlatformL3: 'Taşıma traversleri',
            PlatformL4: 'Hat çevreleme',
            PlatformL5: 'Sundurma imalatları',
            PlatformL6: 'Taşıma arabaları',
            PlatformL7: 'Stok alanlar',
            PlatformL8: 'Hava hatları',
            PlatformL9: 'Serpantin imalatları',
            PlatformL10: 'Sızdırmazlık elemanlarının temini',
            PlatformL11: 'Hidrolik borularının düzenlenmesi ve yenilenmesi',
            PlatformL12: 'Karmaşık sistemlerin sadeleştirilmesi',
            PlatformL13: 'Boyahane havuzları ve hat imalatları',
            MakineB: 'Özel Makine İmalatları',
            MakineA:
                'InsMech, üretiminizi hızlandırmak, kalitenizi artırmak ve otomasyonel üretime geçişinizde çözümleriyle yanınızda. Tamamen size özel tasarlanan ve üretilen makinelerimizle imalatlarınızda fark yaratıyoruz. Otomotiv ve beyaz eşya sektörünün devlerine verdiğimiz hizmetler ve pres makinelerinden gelen tecrübemizle tüm sorunlarınıza çözüm oluyoruz. Tek yapmanız gereken bize ulaşmak. İmalatlarımızdan bazıları:',
            MakineL1: 'Montaj makineleri',
            MakineL2: 'Robotlu kaynak fikstürleri',
            MakineL3: 'Döndürme ekipmanları',
            MakineL4: 'Çizgi izleyen taşıma robotları',
            MakineL5: 'Pres imalatları',
            MakineL6: 'Pnömatik ve hidrolik makineler',
            MakineL7: 'Ölçüm ve test makineleri',
            KonveyörB: 'Özel Konveyör ve Üretim Hatları İmalatları',
            KonveyörA:
                'InsMech, özel konveyör ve üretim hatları imalatlarıyla hizmetinizde. Tecrübeli tasarım ekibimizle hat içi aktarma ve üretim bantları tasarım ve imalatlarında çözüm ortağınız olmaya hazırız. Ürettiğimiz bazı konveyör çeşitleri:',
            KonveyörL1: 'Rulolu Konveyörler',
            KonveyörL2: 'Zincirli Konveyörler',
            KonveyörL3: 'Bant Konveyörler',
            KonveyörL4: 'Akümülasyon zincirli konveyörler',
            KonveyörL5: 'Manyetik konveyörler',
            KonveyörL6: 'Hurda konveyörleri',
            KonveyörL7: 'Asansörlü konveyör hatları',
            KonveyörL8: 'Webb tipi konveyörler',
            FikstürB: 'Fikstür, Manipülatör ve Aparat İmalatları',
            FikstürA:
                'InsMech olarak hassasiyet gerektiren tüm imalatlarınızda, tecrübeli tasarım ekibiyle yanınızdayız. Montaj hatlarınızda fikstür çözümlerimizi kullanarak üretim hızınızı, operatör ergonominizi, imalat kalitenizi ve çalışma alanı düzeninizi artırabilirsiniz. Buna paralel olarak üretim kayıplarınızı en aza indirgeyebilirsiniz. Verdiğimiz bazı fikstür ve manipülatör hizmetlerimiz:',
            FikstürL1: 'Montaj fikstürleri',
            FikstürL2: 'Kaynak fikstürleri',
            FikstürL3: 'Perçin fikstürleri',
            FikstürL4: 'Konumlama fikstürleri',
            FikstürL5: 'Kumlama taşlama fikstürleri',
            FikstürL6: 'Pnömatik & Hidrolik manipülatörler',
            FikstürL7: 'Elektrik aktüatörlü manipülatörler',
            FikstürL8: 'Mekanik manipülatörler',
            TalaşlıB: 'Talaşlı İmalat Hizmetleri',
            TalaşlıA:
                'InsMech talaşlı imalat hizmetleriyle özel parça imalatları, seri imalatlar, makine yedek parça imalatları ile çözüm ortağınız. Verdiğimiz hizmetlerden bazıları:',
            TalaşlıL1: 'CNC talaşlı imalat',
            TalaşlıL2: 'Taşlama',
            TalaşlıL3: 'Hızlı delik delme',
            TalaşlıL4: 'Döküm malzemeler',
            TalaşlıL5: 'Kılavuz çekme',
            TalaşlıL6: 'Kaynaklı imalatlar',
            TalaşlıL7: 'Geniş malzeme yelpazesi',
            TeklifAlButon: 'Teklif Al',
            Tagrak: 'Tagrak Cross Konveyör',
            TagrakD:
                'Astardan çıkan parçaların boya kabinine geçişte 300 kiloluk skidlerin elle taşınması aktarımın tamamiyle operatör kontrolünde yapılması bir iş kaybına ve isg riskine sebebiyet vermekteydi. Hattın otomatik olarak aktarılmasını sağlayan astardan çıkan parçaları boya kabinine otomatik besleyen bir cross konveyör imalatı yapılarak insan gücü kullanımı ve oluşan iş güvenliği riskleri sıfıra indirildi. Cross konveyör alan tarayıcı ve engel algılayıcı sensörler kullanılarak çalıştırılması ve çevredeki tüm hatlarla haberleşmesi üretimdeki hata payını sıfıra indirdi.',
            TagrakA:
                'Parça boyama çevrimi esnasında yapılması gereken hat geçişlerinde insan gücünü sıfıra indirmek.',
            TagrakZ: '6 Ay',
            İletisimeGec: 'Bizimle İletişime Geç',
            İLB: 'BİZİMLE İLETİŞİME GEÇ',
            İLA: 'Bizimle iletişime geçerek bizlerden projelerinize dair teklif alabilir, görüş ve önerilerinizi paylaşabilirsiniz. Bizlere göndereceğiniz mail doğrultusunda sizlerle çok kısa sürede iletişime geçeceğiz.',
            Gönderiliyor: 'Gönderiliyor...',
            MesajınızıAldık: 'Mesajınızı Aldık! ',
            MesajınızıAldıkAçıklama:
                'Mesajınızı bize başarıyla ilettiniz. Size en yakın zamanda geri dönüş sağlayacağız.',
            MesajınızıAlamadık: 'Mesajınız İletilemedi ',
            MesajınızıAlamadıkAçıklama:
                'İletmek istediğiniz mesaj bizlere çeşitli sebeplerden ulaşamadı. Lütfen tekrar deneyiniz. Eğer sorun devam ederse sorunu düzeltmemiz için bekleyin, mutlaka düzelteceğiz.',
            ProjeAmacı: 'Proje Amacı',
            ProjeSahibi: 'Proje Sahibi',
            TeslimSüresi: 'Teslim Süresi',
            Sepet: 'Hava Boruları Aktarma Konveyörü',
            SepetA: 'Hat içerisinde 6 farklı istasyonda üretim aşamalarından geçen boruların istasyonlar arası transferi tamamiyle insan gücüyle yapılmaktaydı. Operatörün hatlar arasında gezmesi ayrıca bir zaman kaybı yaratmaktaydı. Akümülasyon tahrikli konveyör sistemi kurularak bu kayıpların önüne geçildi.',
            SepetD: 'Boruların taşınması için özel sepetler tasarlandı. Bu sebeplerin sonsuz bir çevrim içerisinde çalışması sağlandı. Bu sayede hattın başında dolan sebepler farklı istasyonlarda otomatik olarak dolarak hattın sonunda borular paketlendikten sonra boş bir şekilde aşağıdan giriş kısmına geri dönmektedir. Çevrimin iki uç noktasında bu dönüşü sağlayacak pnömatik asansörler bulunmaktadır. Bu proje sayesinde vardiyada hesaplanan 1 saat 15 dakikalık iş kaybı 10 dakika gibi kısa bir süreye düşürülmüştür.',
            SepetZ: '9 Ay',
            Slat: 'Slat Konveyör',
            SlatA: 'Buzdolaplarının test için bekleme süresini kısaltarak üretimi hızlandırmak.',
            SlatD: 'Buzdolaplarının elektrik testleri için hat sonunda test sırası beklemesi ciddi üretim kayıplarına sebep olmaktaydı. Test edilmesi için gereken prizler hareketli hale getirilerek testin hat akışıyla birlikte yapılması sağlandı. Bu sayede buzdolaplarının test için sıra bekleme problemi ortadan kaldırılmış oldu.',
            SlatZ: '3 Ay',
        },
    },
    en: {
        translation: {
            Language: 'EN',
            'Insmech Size': 'InsMech Provides',
            'Hizmeti Verir': 'Services',
            'Proses İyileştirmeleri': 'Process Improvements',
            'Fikstür İmalatı': 'Fixture Manufacturing',
            'Makine İmalatı': 'Machine Manufacturing',
            AçıklamaHero:
                'Special machinery, engineering services, system commonization, manipulator, fixture, conveyor projects that you need in your production process are made on turnkey basis. Design, automation software and commissioning within the project are carried out by our in-house engineering team. We look forward to cooperating with you with our price advantage and high quality.',
            'iletişim-btn': 'Contact Us',
            'Biz Kimiz?': 'WHO ARE WE?',
            'Biz Kimiz Açıklama':
                'InsMech Engineering, with its experience in the machinery sector and qualified engineering infrastructure, was established with the aim of becoming one of the companies in the Turkish industry that can manufacture instead of importing. In a very short time, InsMech Engineering has succeeded in becoming a solution partner to the giants of the automotive and white goods industry and continues on its way by improving its manufacturing technique and engineering capabilities day by day with the developing technology.',
            'Birlikte Çalışalım': "Let's Work Together",
            Uzmanlıklarımız: 'OUR EXPERTICES',
            Uzmanlık1Başlık: 'Turnkey Projects',
            Uzmanlık1Açıklama:
                'The process of your process from design to manufacturing to electrical panel commissioning is carried out by our experienced engineering team. All processes are continued based on your approval.',
            Uzmanlık2Başlık: 'Engineering Services',
            Uzmanlık2Açıklama:
                'The 3D design of the project is created and the project design process management is carried out together with the analysis results.',
            Uzmanlık3Başlık: 'Panel Manufacturing/Automation',
            Uzmanlık3Açıklama:
                'The panels that cannot be fault monitored and constantly malfunctioning are manufactured with the latest technology systems with industry 4.0 quality, mainly Siemens PLC.',
            Uzmanlık4Başlık: 'Mold Manufacturing',
            Uzmanlık4Açıklama: 'Manufacturing of molds for your parts.',
            AnaSayfa: 'Home',
            Kurumsal: 'Corporate',
            Hizmetlerimiz: 'Services',
            EndüstriyelHizmetler: 'Industrial Services',
            MühendislikHizmetleri: 'Engineering Services',
            İmalatlar: 'Manufacturing',
            Projelerimiz: 'Projects',
            Referanslarımız: 'References',
            Kariyer: 'Career',
            İletişim: 'Contact',
            MakineParkuru: 'OUR MACHINE PARK',
            Makine1Başlık: 'CNC Milling',
            Makine1li1: '600x1500x650mm',
            Makine1li2: '2000x1000x600mm',
            Makine2Başlık: 'Universal Milling',
            Makine2li1: 'Number 4 (2 pcs)',
            Makine2li2: '',
            Makine3Başlık: 'Universal Lathe',
            Makine3li1: 'Diameter 500/Lenght 2000',
            Makine3li2: 'Diameter 300/Lenght 1000',
            Makine4Başlık: 'Welding Machines',
            Makine4li1: '500A (3 pcs)',
            Makine4li2: '400A (3 pcs)',
            Makine5Başlık: 'Band Saw',
            Makine5li1: 'KM350',
            Makine5li2: '',
            NavFooter: 'NAVIGATION',
            HizmetlerimizFooter: 'OUR SERVICES',
            BizeDairFooter: 'ABOUT US',
            İletişimFooter: 'CONTACT',
            EkibimizeKatıl: 'Join Our Team',
            EKatalog: 'Download E-Catalogue',
            Adres: 'Üçevler Neighbourhood, Beşevler Industrial Site, Street 26, Block 39, No: 80 Nilüfer/Bursa',
            MailGönder: 'info@insmech.com',
            TeklifAlİ: 'Request Offer',
            Türkçe: 'Turkish',
            İngilizce: 'English',
            Copyright: '© 2023 InsMech. All rights reserved.',
            CreatedBy: 'Created by ',
            Tarafından: '',
            Nokta: '.',
            Misyonumuz: 'Mission',
            MisyonAçıklama:
                'To be able to serve all manufacturing sectors in machine manufacturing and revision, business improvement, business development and line improvements. To be able to respond to every machine, part, apparatus, fixture and software services requested with special designs.',
            Vizyonumuz: 'Vision',
            VizyonAçıklama:
                'To develop ourselves in parallel with the rapidly developing technology and to join the companies that shape technology with our growing and developing structure. To represent our country in the best way by increasing the number of projects abroad.',
            Sertifika: 'OUR CERTIFICATES',
            KariyerBaşlık: 'WOULD YOU LIKE TO DRAW YOUR CAREER PLAN WITH US?',
            KariyerAçıklama:
                'As InsMech Engineering, which was established with the aim of becoming one of the companies in the Turkish industry that can manufacture instead of importing, we are happy to welcome our teammates with whom we can share our missions and visions.',
            ReferansBaşlık: 'OUR REFERENCES',
            ReferansAçıklama:
                'InsMech, which acts with the awareness of the values of every brand it cooperates with, manages to keep the cost at affordable levels while keeping the quality at the highest level and does not end the processes without smiling faces, is always open to new collaborations!',
            Ref: 'OUR REFERENCES',
            Referansİnceleme: 'See All Our References',
            ProjelerimizBaşlık: 'OUR PROJECTS',
            ProjelerimizAçıklama:
                "We offer everything you need for your production processes, from special machines to engineering services, from system commonization to manipulators, from fixtures to conveyor projects, turnkey. We look forward to cooperating with our customers with competitive price advantages and superior quality. We are happy to share our expertise with you to realize your company's goals and achieve success. Join us and let's start taking steps towards the growth of your business.",
            Kaynakçı: 'Welder',
            KaynakçıAçıklama:
                'We are looking for experienced welders who perform welding processes in accordance with high quality standards. Those who are responsible and team-oriented can apply now to develop their career. Join us now to play an important role in our projects!',
            Tornacı: 'Turner',
            TornacıAçıklama:
                'We are looking for an experienced turner with skills such as machining parts, finishing to quality standards and teamwork skills. Share your certificates and experience with us to contribute to our success goals and develop your career. Join our team to play an important role in engineering projects!',
            Montajcı: 'Machine/Mechanical Assembler',
            MontajcıAçıklama:
                'We are looking for an experienced Machine/Mechanical Assembler to assemble mechanical components, install machine systems and ensure error-free completion of projects. You will follow instructions, work to quality standards and solve problems within the team. By sharing your experience with us, you can play an influential role in engineering projects and develop your career. We are waiting for you to join our journey to success!',
            Bakımcı: 'Machine Maintainer',
            BakımcıAçıklama:
                "We are looking for an experienced Machine Maintainer who is skilled in the maintenance, repair and troubleshooting of machinery and equipment. You will contribute to our company's operations by working in compliance with safety standards and play an important role in engineering projects. Develop your career by sharing your experience and certifications with us. Apply now!",
            AçıkPozisyonlar: 'Job Vacancies',
            İşBaşvuruForm: 'Job Application Form',
            FormBaşlık: 'Upload your CV, apply easily!',
            FormAçıklama:
                "As a first step, upload your CV with '.pdf' extension to the allocated area. Afterwards, if you fill out the form below completely and click the 'Submit Application' button, your application will reach us and we will get back to you.",
            CVBaşlık: 'You can upload your CV by clicking here.',
            CVAçıklama: '*You can only upload documents with PDF extension.',
            CVDurum1: 'File Not Selected',
            CVDurum2: 'The file name you added:',
            AdSoyad: 'Fullname',
            AdPH: 'InsMech',
            Email: 'Email',
            EmailPH: 'info@insmech.com',
            Telefon: 'Phone Number',
            TelefonPH: '0 (224) 441 30 02',
            Şehir: 'Do You Live In Bursa?',
            İlçe: 'Which District Do You Live In?',
            İş: 'Are You Currently Employed?',
            Başlangıç: 'When Can You Start Work?',
            Askerlik: 'Did You Do Your Military Service?',
            Sabıka: 'Do You Have a Criminal Record?',
            Tecrübe: 'Work Experience',
            Eğitim: 'Education Status',
            Maaş: 'What is Your Salary Expectation?',
            Not: 'Is There a Note You Would Like to Leave Us?',
            NotPH: 'Leave your message here',
            Seçim: 'Please make a choice',
            Evet: 'Yes',
            Hayır: 'No',
            Hemen: 'Immediately',
            '1-2Hafta': 'Within 1-2 weeks',
            '3-4Hafta': 'Within 3-4 weeks',
            '0-1': '0-1 year',
            '1-3': '1-3 years',
            '3-5': '3-5 years',
            '5YıldanFazla': 'Over 5 years',
            İlköğretim: 'Elementary Education',
            Lise: 'High School',
            Lisans: "Bachelor's Degree",
            YüksekLisans: "Master's Degree",
            Doktora: 'PhD',
            MaaşDetay: '17.500₺ and up',
            TeklifAl: 'GET AN OFFER',
            İletişimeGeç: 'CONTACT US',
            Bizimleİletişim: 'Contact Us!',
            BizimleİletişimAçıklama:
                'You can get an offer from us by contacting us or you can ask any questions you have in mind.',
            Konu: 'Subject Title',
            KonuPH: 'About InsMech',
            Açıklama: 'Message',
            Mesaj: 'Leave your message',
            TeklifDosya:
                'Click here to attach the file(s) associated with your proposal (if any).',
            Submit: 'Submit',
            İşBaşvuru: '',
            İşBaşvuruLink: 'Click here',
            İşBaşvuruDevam: 'to apply for a job.',
            BaşvuruGönder: 'Submit',
            MHBaşlık: 'ENGINEERING SERVICES',
            MHAçıklama:
                'We contribute to the success of your brand by providing expert engineering services. By collaborating, we can maximize the potential of your projects, increase efficiency and optimize costs. We are here to grow with you with our innovative technology-based solutions.',
            EHBaşlık: 'INDUSTRIAL SERVICES',
            EHAçıklama:
                "Empower your business with our industrial services! Our experienced team optimizes your operations by providing solutions focused on quality, efficiency and sustainability. Working together, we can increase your competitive advantage and achieve success in your industrial projects. Let's work together to grow your brand and achieve your industrial goals!",
            İBaşlık: 'MANUFACTURES',
            İAçıklama:
                'We produce the best solutions for you with our team specialized in manufacturing. With our customer-oriented approach, we ensure timely production that meets quality standards. We are ready to cooperate to meet your manufacturing needs and bring your products to market quickly.',
            TasarımB: 'Design',
            TasarımA:
                "As InsMech, we are here to find solutions to your problems we're here. 10 thousand hours of design capacity with machines, conveyors, fixtures and apparatus in the design of your machines and lines to help you in your recovery we're ready. All you have to do is reach us.",
            Ölçülendirme: 'Dimensioning',
            '3DÖlçüm': '3D measurement',
            Simülasyon: 'Operation simulations',
            Render: '4K rendered images for your presentations',
            İyileştirme: 'Improvement of existing designs',
            AnalizB: 'Analysis',
            AnalizA:
                'InsMech is at your service in every field of production. With our experienced engineers, we continue to add difference and quality to your production with our analysis, measurement and testing services. Some of the services we provide:',
            AnalizL1: 'Static analysis',
            AnalizL2: 'Dynamic analysis',
            AnalizL3: 'Welding tests',
            AnalizL4: '3D crack solution',
            AnalizL5: 'Fatigue tests',
            OtomasyonB: 'Automation',
            OtomasyonA:
                'Some of our automation services we provide with our experienced software and electrical team:',
            OtomasyonL1: 'Panel revisions',
            OtomasyonL2: 'Panel manufacturing and commissioning',
            OtomasyonL3: 'PLC software and commissioning',
            OtomasyonL4: 'Machine electrical and software revisions',
            OtomasyonL5: 'Press electrical and software revisions',
            OtomasyonL6: 'Machine installation and commissioning',
            KaizenB: 'Kaizen/Occupational Safety',
            KaizenA:
                'As InsMech, we are at your side with our services in machine performance, cycle time improvements, machine work and worker safety studies, employee ergonomics studies. Tell us your problems and leave the rest to us. With InsMech, your production will be safer and your productivity will be higher.',
            MakineB: 'Machine Improvements',
            MakineA:
                'As InsMech, we serve with our experienced engineering team in machine revisions, cycle acceleration, equipment renewal, machine occupational health and safety (OHS) revisions, machine transfer and feeding systems revisions and remanufacturing.',
            İyileştirmeB: 'Machine Improvements',
            İyileştirmeA:
                'As InsMech, we serve with our experienced engineering team in machine revisions, cycle acceleration, equipment renewal, machine occupational health and safety (OHS) revisions, machine transfer and feeding systems revisions and remanufacturing.',
            TaşımaB: 'Transportation/Installation',
            TaşımaA:
                'Installation and transfer of machines/lines for the expansion of processes:',
            TaşımaL1: 'Disassembly of machines/processes in working condition',
            TaşımaL2:
                'Determination of loading methods and organization of loading',
            TaşımaL3:
                'Coordination of transportation processes within each other',
            TaşımaL4:
                'Controls of the area to be established and preparation of the ground',
            TaşımaL5: 'Sealing around the floor you may need',
            TaşımaL6: 'Withdrawal of process feed energies',
            TaşımaL7: 'Commissioning of process supply air and water lines',
            TaşımaL8:
                'Design of cooling systems that may be required for the process',
            TaşımaL9: 'Electrical panel revisions when needed',
            ProsesB: 'Process Improvements',
            ProsesA:
                'As InsMech, we provide services in machine performance, cycle time improvements, line improvements, improvement and manufacturing of machine-line transfers and feeds.',
            PlatformB: 'Platforms and Pipelines',
            PlatformA:
                'As InsMech, we enable you to use your factory space more efficiently with our steel construction services.',
            PlatformL1: 'Platform manufacturing',
            PlatformL2: 'Hydraulic & Pneumatic moving platforms',
            PlatformL3: 'Transportation sleepers',
            PlatformL4: 'Line containment',
            PlatformL5: 'Porch manufacturing',
            PlatformL6: 'Transport trolleys',
            PlatformL7: 'Stock areas',
            PlatformL8: 'Air lines',
            PlatformL9: 'Serpentine manufacturing',
            PlatformL10: 'Supply of sealing elements',
            PlatformL11: 'Arrangement and renovation of hydraulic pipes',
            PlatformL12: 'Simplification of complex systems',
            PlatformL13: 'Paint shop pools and line productions',
            MakineB: 'Special Machine Manufacturing',
            MakineA:
                'InsMech is with you with its solutions to speed up your production, increase your quality and transition to automated production. We make a difference in your production with our machines that are completely designed and manufactured specifically for you. With the services we provide to the giants of the automotive and white goods industry and our experience from press machines, we are the solution to all your problems. All you have to do is reach us. Some of our productions:',
            MakineL1: 'Assembly machines',
            MakineL2: 'Robotic welding fixtures',
            MakineL3: 'Rotation equipment',
            MakineL4: 'Line-following transportation robots',
            MakineL5: 'Press manufacturing',
            MakineL6: 'Pneumatic and hydraulic machines',
            MakineL7: 'Measuring and testing machines',
            KonveyörB: 'Special Conveyor and Production Lines Manufacturing',
            KonveyörA:
                'InsMech is at your service with special conveyor and production lines manufacturing. With our experienced design team, we are ready to be your solution partner in the design and manufacturing of in-line conveyors and production lines. Some conveyor types we produce:',
            KonveyörL1: 'Roller Conveyors',
            KonveyörL2: 'Chain Conveyors',
            KonveyörL3: 'Belt Conveyors',
            KonveyörL4: 'Accumulation chain conveyors',
            KonveyörL5: 'Magnetic conveyors',
            KonveyörL6: 'Scrap conveyors',
            KonveyörL7: 'Elevator conveyor lines',
            KonveyörL8: 'Webb type conveyors',
            FikstürB: 'Fixture, Manipulator and Apparatus Manufacturing',
            FikstürA:
                'As InsMech, we are at your side with our experienced design team in all your productions that require precision. By using our fixture solutions in your assembly lines, you can increase your production speed, operator ergonomics, manufacturing quality and workspace organization. In parallel, you can minimize your production losses. Some of our fixture and manipulator services:',
            FikstürL1: 'Mounting fixtures',
            FikstürL2: 'Resource fixtures',
            FikstürL3: 'Rivet fixtures',
            FikstürL4: 'Positioning fixtures',
            FikstürL5: 'Sandblast grinding fixtures',
            FikstürL6: 'Pneumatic & Hydraulic manipulators',
            FikstürL7: 'Electric actuated manipulators',
            FikstürL8: 'Mechanical manipulators',
            TalaşlıB: 'Machining Services',
            TalaşlıA:
                'InsMech is your solution partner with special parts manufacturing, serial manufacturing, machine spare parts manufacturing with machining services. Some of the services we provide:',
            TalaşlıL1: 'CNC machining',
            TalaşlıL2: 'Grinding',
            TalaşlıL3: 'Fast hole drilling',
            TalaşlıL4: 'Casting materials',
            TalaşlıL5: 'Tapping',
            TalaşlıL6: 'Welded manufacturing',
            TalaşlıL7: 'Wide range of materials',
            TeklifAlButon: 'Get An Offer',
            Tagrak: 'Tagrak Cross Conveyor',
            TagrakD:
                'The transfer of the parts coming out of the primer to the paint booth, the manual transportation of the 300-kilogram skids, and the transfer of the transfer completely under the control of the operator caused a loss of work and an occupational safety risk. The use of manpower and occupational safety risks were reduced to zero by manufacturing a cross conveyor that automatically feeds the parts coming out of the primer to the paint booth, which ensures the automatic transfer of the line. The cross conveyor was operated using area scanner and obstacle detection sensors and communicated with all surrounding lines, reducing the margin of error in production to zero.',
            TagrakA:
                'Reducing manpower to zero in line transitions that need to be made during the part painting cycle.',
            TagrakZ: '6 Months',
            İletisimeGec: 'Contact Us',
            İLB: 'CONTACT US',
            İLA: 'By contacting us, you can get an offer from us for your projects and share your opinions and suggestions. We will contact you in a very short time in line with the e-mail you will send us.',
            Gönderiliyor: 'Sending...',
            MesajınızıAldık: 'We got your message! ',
            MesajınızıAldıkAçıklama:
                'You have successfully sent us your message. We will get back to you as soon as possible.',
            MesajınızıAlamadık: 'Your Message Could Not Be Delivered ',
            MesajınızıAlamadıkAçıklama:
                'The message you wanted to send could not reach us for various reasons. Please try again. If the problem persists, please wait for us to fix the problem, we will definitely fix it.',
            ProjeAmacı: 'Project Objective',
            ProjeSahibi: 'Project Owner',
            TeslimSüresi: 'Delivery Time',
            Sepet: 'Air Pipe Transfer Conveyor',
            SepetA: "The transfer of the pipes, which went through the production stages at 6 different stations within the line, was carried out entirely by manpower. The operator's traveling between the lines was also a waste of time. These losses were prevented by installing an accumulation driven conveyor system.",
            SepetD: 'Special baskets were designed for transporting the pipes. These reasons were made to work in an infinite cycle. In this way, the reasons that are filled at the beginning of the line are automatically filled at different stations, and at the end of the line, after the pipes are packed, they return empty to the entrance section from below. There are pneumatic elevators at the two endpoints of the loop to ensure this return. Thanks to this project, the calculated work loss of 1 hour and 15 minutes per shift has been reduced to as little as 10 minutes.',
            SepetZ: '9 Months',
            Slat: 'Slat Conveyor',
            SlatA: 'Speeding up production by shortening the waiting time of refrigerators for testing.',
            SlatD: 'Refrigerators were waiting in line at the end of the line for electrical tests, causing serious production losses. The sockets required for testing were made movable and the test was carried out with the line flow. In this way, the problem of refrigerators waiting in line for testing was eliminated.',
            SlatZ: '3 Months',
        },
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'tr', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
