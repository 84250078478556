import React from 'react';

export default function OurExpertiseCard(props) {
    return (
        <>
            <div className='bg-secondary-100 p-8 border border-secondary-200 rounded-[20px] h-full flex flex-col items-center gap-y-3 hover:bg-card-stroke group hover:bg-secondary transition-all duration-150'>
                <img
                    src={props.icon}
                    className='mb-4 w-8'
                    alt='expertise-icon'
                />
                <h2 className='text-white font-bold group-hover:text-primary'>
                    {props.title}
                </h2>
                <p className='text-secondary-400'>{props.description}</p>
            </div>
        </>
    );
}
