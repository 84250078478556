import React from 'react';
import cardsContent from '../../api/OurExpertise.json';
import OurExpertiseCard from './card/OurExpertiseCard';
import { useTranslation } from 'react-i18next';

export default function OurExpertise() {
    const { t, i18n } = useTranslation();
    return (
        <section className='container pt-20 flex flex-col items-center justify-center gap-y-4 text-center leading-tight'>
            <h2 className='text-white font-bold text-xl'>
                {t('Uzmanlıklarımız')}
            </h2>
            <div className='w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4'>
                {cardsContent.map((cardsContent) => (
                    <OurExpertiseCard
                        icon={cardsContent.icon}
                        title={t(`${cardsContent.title}`)} // kanka aynı şekilde json dosyasında da ters mantık yapacaksın sonra buraya ordaki adı çekecek ve karşılığı gelecek bi tanesini örnek yapar mısın kafam basmadıooki
                        description={t(`${cardsContent.description}`)}
                    />
                ))}
            </div>
        </section>
    );
}
