import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function EngineeringServices() {
    const { t, i18n } = useTranslation();
    return (
        <section className='container min-h-screen py-20 flex flex-col items-center justify-start gap-y-8'>
            <div className='text-center'>
                <h1 className='text-tertiary text-4xl font-bold mb-2'>
                    {t('MHBaşlık')}
                </h1>
                <p className='text-secondary-400'>{t('MHAçıklama')}</p>
                <Link
                    to='/iletisim'
                    className='inline-flex items-center justify-center mt-8 gap-x-3 group'
                >
                    <span className='text-white font-semibold group-hover:text-primary '>
                        {t('Birlikte Çalışalım')}
                    </span>
                    <span>
                        <svg
                            width='8'
                            height='13'
                            viewBox='0 0 8 13'
                            fill='currentColor'
                            xmlns='http://www.w3.org/2000/svg'
                            className='group-hover:text-primary text-white'
                        >
                            <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M7.40001 6.50001C7.40001 6.76523 7.29465 7.01958 7.10712 7.20712L2.30712 12.0071C1.91659 12.3976 1.28343 12.3976 0.892902 12.0071C0.502378 11.6166 0.502378 10.9834 0.892902 10.5929L4.9858 6.50001L0.892903 2.40712C0.502378 2.01659 0.502378 1.38343 0.892903 0.992905C1.28343 0.602381 1.91659 0.602381 2.30712 0.992905L7.10712 5.79291C7.29465 5.98044 7.40001 6.2348 7.40001 6.50001Z'
                                fill='currentColor'
                            />
                        </svg>
                    </span>
                </Link>
            </div>
            <div className='grid grid-cols-4 w-full gap-4 relative'>
                {/* Tasarım */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 rounded-xl overflow-hidden border border-secondary-200 flex flex-col justify-between'>
                    <div>
                        <img
                            src='services/tasarim.jpg'
                            className='aspect-video object-cover'
                            alt='tasarim'
                        />
                        <div className='p-6'>
                            <h2 className='font-bold text-xl mb-2 text-tertiary'>
                                {t('TasarımB')}
                            </h2>
                            <p className='text-secondary-400 mb-2'>
                                {t('TasarımA')}
                            </p>
                            <ul className='list-disc pl-4 text-secondary-400'>
                                <li>{t('Ölçülendirme')}</li>
                                <li>{t('3DÖlçüm')}</li>
                                <li>{t('Simülasyon')}</li>
                                <li>{t('Render')}</li>
                                <li>{t('İyileştirme')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='text-right p-6'>
                        <Link
                            to='/iletisim'
                            className='bg-transparent py-1 px-4 border-2 border-primary rounded-lg text-primary font-bold hover:text-tertiary hover:bg-primary'
                        >
                            Teklif Al
                        </Link>
                    </div>
                </div>
                {/* Analiz */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 rounded-xl overflow-hidden border border-secondary-200 flex flex-col justify-between'>
                    <div>
                        <img
                            src='/services/analiz.jpg'
                            className='aspect-video object-cover'
                            alt='analysis'
                        />
                        <div className='p-6'>
                            <h2 className='font-bold text-xl mb-2 text-tertiary'>
                                {t('AnalizB')}
                            </h2>
                            <p className='text-secondary-400 mb-2'>
                                {t('AnalizA')}
                            </p>
                            <ul className='list-disc pl-4 text-secondary-400'>
                                <li>{t('AnalizL1')}</li>
                                <li>{t('AnalizL2')}</li>
                                <li>{t('AnalizL3')}</li>
                                <li>{t('AnalizL4')}</li>
                                <li>{t('AnalizL5')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='text-right p-6'>
                        <Link
                            to='/iletisim'
                            className='bg-transparent py-1 px-4 border-2 border-primary rounded-lg text-primary font-bold hover:text-tertiary hover:bg-primary'
                        >
                            Teklif Al
                        </Link>
                    </div>
                </div>
                {/* Otomasyon */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 rounded-xl overflow-hidden border border-secondary-200 flex flex-col justify-between'>
                    <div>
                        <img
                            src='/services/otomasyon.jpg'
                            className='aspect-video object-cover'
                            alt='otomasyon'
                        />
                        <div className='p-6'>
                            <h2 className='font-bold text-xl mb-2 text-tertiary'>
                                {t('OtomasyonB')}
                            </h2>
                            <p className='text-secondary-400 mb-2'>
                                {t('OtomasyonA')}
                            </p>
                            <ul className='list-disc pl-4 text-secondary-400'>
                                <li>{t('OtomasyonL1')}</li>
                                <li>{t('OtomasyonL2')}</li>
                                <li>{t('OtomasyonL3')}</li>
                                <li>{t('OtomasyonL4')}</li>
                                <li>{t('OtomasyonL5')}</li>
                                <li>{t('OtomasyonL6')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='text-right p-6'>
                        <Link
                            to='/iletisim'
                            className='bg-transparent py-1 px-4 border-2 border-primary rounded-lg text-primary font-bold hover:text-tertiary hover:bg-primary'
                        >
                            Teklif Al
                        </Link>
                    </div>
                </div>
                {/* Kaizen/İş Güvenliği */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 rounded-xl overflow-hidden border border-secondary-200 flex flex-col justify-between'>
                    <div>
                        <img
                            src='/services/kaizen.jpg'
                            className='aspect-video object-cover'
                            alt='kaizen'
                        />
                        <div className='p-6'>
                            <h2 className='font-bold text-xl mb-2 text-tertiary'>
                                {t('KaizenB')}
                            </h2>
                            <p className='text-secondary-400 mb-2'>
                                {t('KaizenA')}
                            </p>
                        </div>
                    </div>
                    <div className='text-right p-6'>
                        <Link
                            to='/iletisim'
                            className='bg-transparent py-1 px-4 border-2 border-primary rounded-lg text-primary font-bold hover:text-tertiary hover:bg-primary'
                        >
                            Teklif Al
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
