import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function OurReferences() {
    const { t, i18n } = useTranslation();
    return (
        <section className='container py-20 flex flex-col items-center justify-center gap-y-4 leading-tight '>
            <h2 className='text-white font-bold text-xl'>{t('Ref')}</h2>
            {/* grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6*/}
            <div className='grid grid-cols-6 gap-4 relative before:absolute before:z-10 before:top-0 before:left-0 before:w-full before:h-full before:bg-gradient-to-r before:from-secondary before:via-transparent before:to-secondary'>
                <div className='col-span-3 lg:col-span-1 bg-secondary-100 p-10 rounded-xl border border-secondary-200 '>
                    <img
                        src='/references/beycelikgestamp.png'
                        alt='reference-logo'
                    />
                </div>
                <div className='col-span-3 lg:col-span-1 bg-secondary-100 p-10 rounded-xl border border-secondary-200'>
                    <img src='/references/arcelik.png' alt='reference-logo' />
                </div>
                <div className='col-span-3 lg:col-span-1 bg-secondary-100 p-10 rounded-xl border border-secondary-200'>
                    <img src='/references/togg.png' alt='reference-logo' />
                </div>
                <div className='col-span-3 lg:col-span-1 bg-secondary-100 p-10 rounded-xl border border-secondary-200'>
                    <img src='/references/ford.png' alt='reference-logo' />
                </div>
                <div className='col-span-3 lg:col-span-1 bg-secondary-100 p-2 rounded-xl border border-secondary-200 '>
                    <img src='/references/karsan.png' alt='reference-logo' />
                </div>
                <div className='col-span-3 lg:col-span-1 bg-secondary-100 p-10 rounded-xl border border-secondary-200'>
                    <img src='/references/martur.png' alt='reference-logo' />
                </div>
            </div>
            {/* See all our references */}
            <div className='mt-4'>
                <Link
                    to='/referanslarimiz'
                    className='flex items-center gap-x-3 group'
                >
                    <span className='text-white font-semibold group-hover:text-primary '>
                        {t('Referansİnceleme')}
                    </span>
                    <span>
                        <svg
                            width='8'
                            height='13'
                            viewBox='0 0 8 13'
                            fill='currentColor'
                            xmlns='http://www.w3.org/2000/svg'
                            className='group-hover:text-primary text-white'
                        >
                            <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M7.40001 6.50001C7.40001 6.76523 7.29465 7.01958 7.10712 7.20712L2.30712 12.0071C1.91659 12.3976 1.28343 12.3976 0.892902 12.0071C0.502378 11.6166 0.502378 10.9834 0.892902 10.5929L4.9858 6.50001L0.892903 2.40712C0.502378 2.01659 0.502378 1.38343 0.892903 0.992905C1.28343 0.602381 1.91659 0.602381 2.30712 0.992905L7.10712 5.79291C7.29465 5.98044 7.40001 6.2348 7.40001 6.50001Z'
                                fill='currentColor'
                            />
                        </svg>
                    </span>
                </Link>
            </div>
        </section>
    );
}
