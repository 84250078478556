import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import InsmechAmblem from '../../assets/insmech-amblem.svg';
import { Fade as Hamburger } from 'hamburger-react';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';

function Navbar() {
    const { t, i18n } = useTranslation();
    const [isNavOpen, setIsNavOpen] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', function () {
            const navbar = document.getElementById('navbar');
            if (window.scrollY > 50) {
                navbar.classList.add('bg-secondary');
                navbar.classList.remove('bg-transparent');
            } else {
                navbar.classList.add('bg-transparent');
                navbar.classList.remove('bg-secondary');
            }
        });
    });

    {
        /*   useEffect(() => {
        console.log(isNavOpen);
        const menu = document.getElementById('menu');
        const navbar = document.getElementById('navbar');
        const services = document.getElementById('services');
        document.addEventListener('click', function (event) {
            console.log(event);
            if (menu.contains(event.target) || navbar.contains(event.target)) {
                return;
            } else {
                toggleMenu();
            }
        });
    }, [isNavOpen]);

    const toggleMenu = () => {
        setIsNavOpen(false);
    };

    */
    }

    return (
        <header>
            <nav
                className='bg-transparent w-full h-20 fixed z-50 !top-0 transition ease-in-out delay-50'
                id='navbar'
            >
                <div className='w-full h-full px-4 md:px-16 flex items-center justify-between'>
                    <Link to='/' className='text-lg font-bold text-black '>
                        <img
                            src={InsmechAmblem}
                            width='151px'
                            height='23px'
                            alt='Insmech Logo'
                        />
                    </Link>
                    <div
                        className={` ${
                            isNavOpen ? 'flex flex-col' : 'hidden'
                        } lg:flex absolute lg:relative bg-primary lg:bg-transparent right-0 top-0 w-4/5 h-screen lg:h-fit lg:flex-row lg:items-center justify-center lg:justify-end space-y-6 lg:space-y-0 lg:space-x-6 px-10 sm:px-14 lg:px-0 text-xl lg:text-base `}
                        id='menu'
                    >
                        <Link
                            to='/'
                            className=' text-white font-bold  transition ease-in-out delay-150 hover:text-secondary lg:hover:text-primary focus:text-secondary lg:focus:text-primary active:text-secondary lg:active:text-primary'
                        >
                            {t('AnaSayfa')}
                        </Link>
                        <Link
                            to='/kurumsal'
                            className=' text-white font-bold  transition ease-in-out delay-150 hover:text-secondary lg:hover:text-primary focus:text-secondary lg:focus:text-primary active:text-secondary lg:active:text-primary'
                        >
                            {t('Kurumsal')}
                        </Link>
                        <div className='dropdown'>
                            <label
                                tabIndex={0}
                                className='btn bg-transparent p-0 inline border-none normal-case text-tertiary font-bold text-xl lg:text-base hover:text-secondary lg:hover:text-primary focus:text-secondary lg:focus:text-primary active:text-secondary lg:active:text-primary hover:bg-transparent'
                                id='services'
                            >
                                {t('Hizmetlerimiz')}
                            </label>
                            <ul
                                tabIndex={0}
                                className='dropdown-content menu p-2 drop-shadow-xl bg-secondary-100 rounded-box w-52 mt-2 border border-secondary-200'
                            >
                                <li>
                                    <Link
                                        to='/muhendislik-hizmetleri'
                                        className='text-tertiary hover:bg-secondary-200'
                                    >
                                        {t('MühendislikHizmetleri')}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to='/endustriyel-hizmetler'
                                        className='text-tertiary hover:bg-secondary-200'
                                    >
                                        {t('EndüstriyelHizmetler')}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to='/imalatlar'
                                        className='text-tertiary hover:bg-secondary-200'
                                    >
                                        {t('İmalatlar')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <Link
                            to='/projelerimiz'
                            className=' text-white font-bold  transition ease-in-out delay-150 hover:text-secondary lg:hover:text-primary focus:text-secondary lg:focus:text-primary active:text-secondary lg:active:text-primary'
                        >
                            {t('Projelerimiz')}
                        </Link>
                        <Link
                            to='/referanslarimiz'
                            className=' text-white font-bold  transition ease-in-out delay-150 hover:text-secondary lg:hover:text-primary focus:text-secondary lg:focus:text-primary active:text-secondary lg:active:text-primary'
                        >
                            {t('Referanslarımız')}
                        </Link>
                        <Link
                            to='/kariyer'
                            className=' text-white font-bold  transition ease-in-out delay-150 hover:text-secondary lg:hover:text-primary focus:text-secondary lg:focus:text-primary active:text-secondary lg:active:text-primary'
                        >
                            {t('Kariyer')}
                        </Link>
                        <Link
                            to='/iletisim'
                            className=' text-white font-bold  transition ease-in-out delay-150 hover:text-secondary lg:hover:text-primary focus:text-secondary lg:focus:text-primary active:text-secondary lg:active:text-primary'
                        >
                            {t('İletişim')}
                        </Link>
                        <button
                            onClick={() =>
                                i18n.changeLanguage(
                                    i18n.language === 'tr-TR'
                                        ? 'en-US'
                                        : 'tr-TR'
                                )
                            }
                            className=' text-white font-bold  transition ease-in-out delay-150 hover:text-secondary lg:hover:text-primary focus:text-secondary lg:focus:text-primary active:text-secondary lg:active:text-primary text-left'
                        >
                            {t('Language')}
                        </button>
                    </div>
                    {/* Navbar Menu Icon */}
                    <button
                        type='button'
                        className='lg:hidden relative z-[100] cursor-pointer'
                        onClick={() => setIsNavOpen((prev) => !prev)}
                        id='menu-button'
                    >
                        <Hamburger
                            size={24}
                            color='#F6FBFE'
                            toggled={isNavOpen}
                        />
                    </button>
                </div>
            </nav>
        </header>
    );
}

export default Navbar;
