import React from 'react';
import ButtonSecondary from '../../FormElements/ButtonSecondary';

export default function JobAdvCard(props) {
    return (
        <>
            <div className='bg-secondary border border-secondary-200 p-6 col-span-3 lg:col-span-1 rounded-[20px] flex flex-col items-start justify-between gap-y-6'>
                <div className='w-full flex justify-between'>
                    <div className='text-left'>
                        <h2 className='text-tertiary font-bold mb-1 text-xl'>
                            {props.title}
                        </h2>
                        <p className='text-secondary-400 wb-break-words'>
                            {props.description}
                        </p>
                    </div>
                    <ul>
                        <li className='block w-4 h-4 rounded-full animate-pulse bg-succes'></li>
                    </ul>
                </div>
            </div>
        </>
    );
}
