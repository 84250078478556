import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function References() {
    const { t, i18n } = useTranslation();
    return (
        <section className='container min-h-screen py-20 flex flex-col items-center justify-start gap-y-8 text-center'>
            {/* Page Description */}
            <div>
                <h1 className='text-tertiary text-4xl font-bold mb-2'>
                    {t('ReferansBaşlık')}
                </h1>
                <p className='text-secondary-400'>{t('ReferansAçıklama')}</p>
                <Link
                    to='/iletisim'
                    className='inline-flex items-center justify-center mt-8 gap-x-3 group'
                >
                    <span className='text-white font-semibold group-hover:text-primary '>
                        {t('Birlikte Çalışalım')}
                    </span>
                    <span>
                        <svg
                            width='8'
                            height='13'
                            viewBox='0 0 8 13'
                            fill='currentColor'
                            xmlns='http://www.w3.org/2000/svg'
                            className='group-hover:text-primary text-white'
                        >
                            <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M7.40001 6.50001C7.40001 6.76523 7.29465 7.01958 7.10712 7.20712L2.30712 12.0071C1.91659 12.3976 1.28343 12.3976 0.892902 12.0071C0.502378 11.6166 0.502378 10.9834 0.892902 10.5929L4.9858 6.50001L0.892903 2.40712C0.502378 2.01659 0.502378 1.38343 0.892903 0.992905C1.28343 0.602381 1.91659 0.602381 2.30712 0.992905L7.10712 5.79291C7.29465 5.98044 7.40001 6.2348 7.40001 6.50001Z'
                                fill='currentColor'
                            />
                        </svg>
                    </span>
                </Link>
            </div>

            {/* References */}
            <div className='grid grid-cols-4 gap-4 w-full justify-center'>
                {/* Togg */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 border-2 border-transparent hover:bg-secondary hover:border-secondary-200 rounded-xl p-20 hover:p-10 group transition-all duration-500 flex items-center justify-center min-h-[366px]'>
                    <img
                        src='/references/togg.png'
                        className='block group-hover:hidden'
                    />
                    <div className='hidden group-hover:block'>
                        <div className='mb-4'>
                            <h2 className='text-secondary-400'>
                                İş Birliği Süresi
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                2 Yıl
                            </span>
                        </div>
                        <div>
                            <h2 className='text-secondary-400'>
                                Yapılan Proje Sayısı
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                6 Proje
                            </span>
                        </div>
                    </div>
                </div>
                {/* Ford */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 border-2 border-transparent hover:bg-secondary hover:border-secondary-200 rounded-xl p-20 hover:p-10 group transition-all duration-500 flex items-center justify-center min-h-[366px]'>
                    <img
                        src='/references/ford.png'
                        className='block group-hover:hidden'
                    />
                    <div className='hidden group-hover:block'>
                        <div className='mb-4'>
                            <h2 className='text-secondary-400'>
                                İş Birliği Süresi
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                8 Yıl
                            </span>
                        </div>
                        <div>
                            <h2 className='text-secondary-400'>
                                Yapılan Proje Sayısı
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                434 Proje
                            </span>
                        </div>
                    </div>
                </div>
                {/* Arçelik*/}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 border-2 border-transparent hover:bg-secondary hover:border-secondary-200 rounded-xl p-20 hover:p-10 group transition-all duration-500 flex items-center justify-center min-h-[366px]'>
                    <img
                        src='/references/arcelik.png'
                        className='block group-hover:hidden'
                    />
                    <div className='hidden group-hover:block'>
                        <div className='mb-4'>
                            <h2 className='text-secondary-400'>
                                İş Birliği Süresi
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                8 Yıl
                            </span>
                        </div>
                        <div>
                            <h2 className='text-secondary-400'>
                                Yapılan Proje Sayısı
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                147 Proje
                            </span>
                        </div>
                    </div>
                </div>
                {/* Martur */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 border-2 border-transparent hover:bg-secondary hover:border-secondary-200 rounded-xl p-20 hover:p-10 group transition-all duration-500 flex items-center justify-center min-h-[366px]'>
                    <img
                        src='/references/martur.png'
                        className='block group-hover:hidden'
                    />
                    <div className='hidden group-hover:block'>
                        <div className='mb-4'>
                            <h2 className='text-secondary-400'>
                                İş Birliği Süresi
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                6 Yıl
                            </span>
                        </div>
                        <div>
                            <h2 className='text-secondary-400'>
                                Yapılan Proje Sayısı
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                22 Proje
                            </span>
                        </div>
                    </div>
                </div>
                {/* Coşkunöz*/}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 border-2 border-transparent hover:bg-secondary hover:border-secondary-200 rounded-xl p-20 hover:p-10 group transition-all duration-500 flex items-center justify-center min-h-[366px]'>
                    <img
                        src='/references/coskunoz.png'
                        className='block group-hover:hidden'
                    />
                    <div className='hidden group-hover:block'>
                        <div className='mb-4'>
                            <h2 className='text-secondary-400'>
                                İş Birliği Süresi
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                6 Yıl
                            </span>
                        </div>
                        <div>
                            <h2 className='text-secondary-400'>
                                Yapılan Proje Sayısı
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                50 Proje
                            </span>
                        </div>
                    </div>
                </div>
                {/* Ermetal */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 border-2 border-transparent hover:bg-secondary hover:border-secondary-200 rounded-xl p-20 hover:p-10 group transition-all duration-500 flex items-center justify-center min-h-[366px]'>
                    <img
                        src='/references/ermetal.png'
                        className='block group-hover:hidden'
                    />
                    <div className='hidden group-hover:block'>
                        <div className='mb-4'>
                            <h2 className='text-secondary-400'>
                                İş Birliği Süresi
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                4 Yıl
                            </span>
                        </div>
                        <div>
                            <h2 className='text-secondary-400'>
                                Yapılan Proje Sayısı
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                21 Proje
                            </span>
                        </div>
                    </div>
                </div>
                {/* Magneti Marelli */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 border-2 border-transparent hover:bg-secondary hover:border-secondary-200 rounded-xl p-20 hover:p-10 group transition-all duration-500 flex items-center justify-center min-h-[366px]'>
                    <img
                        src='/references/magnetimarelli.png'
                        className='block group-hover:hidden'
                    />
                    <div className='hidden group-hover:block'>
                        <div className='mb-4'>
                            <h2 className='text-secondary-400'>
                                İş Birliği Süresi
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                8 Yıl
                            </span>
                        </div>
                        <div>
                            <h2 className='text-secondary-400'>
                                Yapılan Proje Sayısı
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                352 Proje
                            </span>
                        </div>
                    </div>
                </div>
                {/* Karsan */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 border-2 border-transparent hover:bg-secondary hover:border-secondary-200 rounded-xl p-20 hover:p-10 group transition-all duration-500 flex items-center justify-center min-h-[366px]'>
                    <img
                        src='/references/karsan.png'
                        className='block group-hover:hidden'
                    />
                    <div className='hidden group-hover:block'>
                        <div className='mb-4'>
                            <h2 className='text-secondary-400'>
                                İş Birliği Süresi
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                1 Yıl
                            </span>
                        </div>
                        <div>
                            <h2 className='text-secondary-400'>
                                Yapılan Proje Sayısı
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                2 Proje
                            </span>
                        </div>
                    </div>
                </div>
                {/* Fagor */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 border-2 border-transparent hover:bg-secondary hover:border-secondary-200 rounded-xl p-20 hover:p-10 group transition-all duration-500 flex items-center justify-center min-h-[366px]'>
                    <img
                        src='/references/fagor.png'
                        className='block group-hover:hidden'
                    />
                    <div className='hidden group-hover:block'>
                        <div className='mb-4'>
                            <h2 className='text-secondary-400'>
                                İş Birliği Süresi
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                8 Yıl
                            </span>
                        </div>
                        <div>
                            <h2 className='text-secondary-400'>
                                Yapılan Proje Sayısı
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                140 Proje
                            </span>
                        </div>
                    </div>
                </div>
                {/* Haier */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 border-2 border-transparent hover:bg-secondary hover:border-secondary-200 rounded-xl p-20 hover:p-10 group transition-all duration-500 flex items-center justify-center min-h-[366px]'>
                    <img
                        src='/references/haier.png'
                        className='block group-hover:hidden'
                    />
                    <div className='hidden group-hover:block'>
                        <div className='mb-4'>
                            <h2 className='text-secondary-400'>
                                İş Birliği Süresi
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                6 Yıl
                            </span>
                        </div>
                        <div>
                            <h2 className='text-secondary-400'>
                                Yapılan Proje Sayısı
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                43 Proje
                            </span>
                        </div>
                    </div>
                </div>
                {/* Beyçelik Gestamp */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 border-2 border-transparent hover:bg-secondary hover:border-secondary-200 rounded-xl p-20 hover:p-10 group transition-all duration-500 flex items-center justify-center min-h-[366px]'>
                    <img
                        src='/references/beycelikgestamp.png'
                        className='block group-hover:hidden'
                    />
                    <div className='hidden group-hover:block'>
                        <div className='mb-4'>
                            <h2 className='text-secondary-400'>
                                İş Birliği Süresi
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                8 Yıl
                            </span>
                        </div>
                        <div>
                            <h2 className='text-secondary-400'>
                                Yapılan Proje Sayısı
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                34 Proje
                            </span>
                        </div>
                    </div>
                </div>
                {/* Asil Çelik */}
                <div className='col-span-4 md:col-span-2 xl:col-span-1 bg-secondary-100 border-2 border-transparent hover:bg-secondary hover:border-secondary-200 rounded-xl p-20 hover:p-10 group transition-all duration-500 flex items-center justify-center min-h-[366px]'>
                    <img
                        src='/references/asilcelik.png'
                        className='block group-hover:hidden'
                    />
                    <div className='hidden group-hover:block'>
                        <div className='mb-4'>
                            <h2 className='text-secondary-400'>
                                İş Birliği Süresi
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                8 Yıl
                            </span>
                        </div>
                        <div>
                            <h2 className='text-secondary-400'>
                                Yapılan Proje Sayısı
                            </h2>
                            <span className='text-tertiary font-bold text-2xl'>
                                18 Proje
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
