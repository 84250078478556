import React from 'react';
import Slider from 'react-slick';
import Typewriter from 'typewriter-effect';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function HeroSection() {
    const { t, i18n } = useTranslation();
    var settings = {
        dots: false,
        infinite: true,
        arrows: false,
        autoplay: true,
        speed: 2500,
        autoplaySpeed: 8000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <section className='relative before:bg-black before:opacity-80 before:w-full before:h-full before:absolute before:top-0 before:z-10 -mt-20'>
            <Slider {...settings}>
                <div>
                    <img
                        src='/carousel/carousel-1.jpg'
                        className='w-full h-screen object-cover'
                        alt='slider-1'
                    />
                </div>
                <div>
                    <img
                        src='/carousel/carousel-2.jpg'
                        className='w-full h-screen object-cover'
                        alt='slider-2'
                    />
                </div>
                <div>
                    <img
                        src='/carousel/carousel-3.jpg'
                        className='w-full h-screen object-cover'
                        alt='slider-2'
                    />
                </div>
            </Slider>
            <div className='container flex flex-col justify-center items-center absolute top-0 h-full left-1/2 -translate-x-1/2 z-20 gap-y-6'>
                <h1 className='text-white text-center text-5xl md:text-6xl font-black'>
                    {t('Insmech Size')}
                    <span className='text-primary'>
                        <Typewriter
                            options={{
                                strings: [
                                    `${t('Proses İyileştirmeleri')}`,
                                    `${t('Fikstür İmalatı')}`,
                                    `${t('Makine İmalatı')}`,
                                ],
                                autoStart: true,
                                loop: true,
                                delay: 100,
                                deleteSpeed: 100,
                                pauseFor: 6000,
                            }}
                        />
                    </span>
                    {t('Hizmeti Verir')}
                </h1>
                <p className='text-secondary-500 text-center w-full md:w-1/2'>
                    {t('AçıklamaHero')}
                </p>
                <Link
                    to='iletisim'
                    className='bg-primary flex items-center justify-center py-3 px-6 rounded-lg transition text-white font-bold hover:bg-white hover:text-secondary hover:scale-105'
                >
                    {t('iletişim-btn')}
                </Link>
            </div>
        </section>
    );
}
