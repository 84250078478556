import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function OurProjects() {
    const { t, i18n } = useTranslation();
    return (
        <section className='container py-20 flex flex-col items-center justify-center gap-y-8 text-center'>
            <div>
                <h1 className='text-tertiary text-4xl font-bold mb-2'>
                    {t('ProjelerimizBaşlık')}
                </h1>
                <p className='text-secondary-400'>
                    {t('ProjelerimizAçıklama')}
                </p>
                <Link
                    to='/iletisim'
                    className='inline-flex items-center justify-center mt-8 gap-x-3 group'
                >
                    <span className='text-white font-semibold group-hover:text-primary '>
                        {t('Birlikte Çalışalım')}
                    </span>
                    <span>
                        <svg
                            width='8'
                            height='13'
                            viewBox='0 0 8 13'
                            fill='currentColor'
                            xmlns='http://www.w3.org/2000/svg'
                            className='group-hover:text-primary text-white'
                        >
                            <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M7.40001 6.50001C7.40001 6.76523 7.29465 7.01958 7.10712 7.20712L2.30712 12.0071C1.91659 12.3976 1.28343 12.3976 0.892902 12.0071C0.502378 11.6166 0.502378 10.9834 0.892902 10.5929L4.9858 6.50001L0.892903 2.40712C0.502378 2.01659 0.502378 1.38343 0.892903 0.992905C1.28343 0.602381 1.91659 0.602381 2.30712 0.992905L7.10712 5.79291C7.29465 5.98044 7.40001 6.2348 7.40001 6.50001Z'
                                fill='currentColor'
                            />
                        </svg>
                    </span>
                </Link>
            </div>
            <div className='grid grid-cols-2 items-start gap-4'>
                {/* Left Side */}
                <div className='col-span-2 lg:col-span-1 flex flex-col gap-4'>
                    <Link to='/tagrak-shuttle-skid-aktarma' className=''>
                        <div className='relative rounded-xl overflow-hidden border-2 border-secondary-200 before:bg-gradient-to-t before:from-black before:absolute before:z-10 before:top-0 before:left-0 before:w-full before:h-full group '>
                            <img
                                src='/projects/tagrak/tagrak.jpg'
                                className='aspect-square md:aspect-video object-cover group-hover:scale-105 transition-all duration-1000'
                            />
                            <div className='text-tertiary absolute z-20 bottom-0 left-0 w-full px-4 lg:px-8 py-4 text-left group-hover:scale-[1.02] transition-all duration-1000'>
                                <h1 className='text-2xl font-bold'>
                                    {t('Tagrak')}
                                </h1>
                                <p className='text-secondary-400 hidden md:block'>
                                    {t('TagrakA')}
                                </p>
                            </div>
                        </div>
                    </Link>
                    <Link to='/slat'>
                        <div className='relative rounded-xl overflow-hidden border-2 border-secondary-200 before:bg-gradient-to-t before:from-black before:absolute before:z-10 before:top-0 before:left-0 before:w-full before:h-full group'>
                            <img
                                src='/projects/slat/slat-1.jpg'
                                className='aspect-square md:aspect-video lg:aspect-square object-cover group-hover:scale-105 transition-all duration-1000'
                            />
                            <div className='text-tertiary absolute z-20 bottom-0 w-full px-8 py-4 text-left group-hover:scale-[1.02] transition-all duration-1000'>
                                <h1 className='text-2xl font-bold'>
                                    {t('Slat')}
                                </h1>
                                <p className='text-secondary-400 hidden md:block'>
                                    {t('SlatA')}
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
                {/* Right Side */}
                <div className='col-span-2 lg:col-span-1 flex flex-col gap-4'>
                    <Link to='/sepet-tasima'>
                        <div className='relative rounded-xl overflow-hidden border-2 border-secondary-200 before:bg-gradient-to-t before:from-black before:absolute before:z-10 before:top-0 before:left-0 before:w-full before:h-full group'>
                            <img
                                src='/projects/sepet-tasima/sepet-tasima.jpg'
                                className='aspect-square md:aspect-video lg:aspect-square object-cover group-hover:scale-105 transition-all duration-1000'
                                alt='sepet-tasima-konveyoru'
                            />
                            <div className='text-tertiary absolute z-20 bottom-0 w-full px-8 py-4 text-left group-hover:scale-[1.02] transition-all duration-1000'>
                                <h1 className='text-2xl font-bold'>
                                    {t('Sepet')}
                                </h1>
                                <p className='text-secondary-400 hidden md:block'>
                                    {t('SepetA')}
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </section>
    );
}
