import React from 'react';
import { InputText } from 'primereact/inputtext';
import { useRef } from 'react';

export default function TextInput(props) {
    const inputRef = useRef(null);
    const handleInputChange = (event) => {
        const regex = /^[a-zA-Z\s]*$/; // sadece alfabetik karakterler ve boşluk kabul eder
        const inputValue = event.target.value;
        if (regex.test(inputValue)) {
            inputRef.current.value = inputValue;
        } else {
            inputRef.current.value = inputRef.current.value.slice(0, -1);
        }
    };
    return (
        <>
            <label for={props.for} className='text-white font-semibold'>
                {props.label}
            </label>
            <InputText
                onChange={handleInputChange}
                ref={inputRef}
                id={props.id}
                name={props.name}
                placeholder={props.placeholder}
                className='mt-2 placeholder-secondary-300 text-tertiary px-2 py-4 w-full block bg-secondary focus:bg-secondary-200 border border-secondary-200 hover:border-primary/60 focus:border-primary active:border-primary rounded-md  focus:outline-none'
            />
        </>
    );
}
