import React from 'react';

export default function ButtonPrimary(props) {
    return (
        <>
            <button
                className='cursor-pointer bg-primary w-full text-tertiary px-4 py-2 rounded-lg hover:bg-primary-darken transition-all duration-500 '
                id={props.id}
            >
                {props.title}
            </button>
        </>
    );
}
