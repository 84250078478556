import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function WhoAreWe() {
    const { t, i18n } = useTranslation();
    return (
        <section className='container pt-20 flex flex-col items-center justify-center gap-y-4 text-center leading-tight '>
            <div className='md:w-4/5'>
                <h2 className='text-white font-bold text-xl mb-2'>
                    {t('Biz Kimiz?')}
                </h2>
                <p className='text-secondary-400 '>{t('Biz Kimiz Açıklama')}</p>
            </div>
            <Link to='/kariyer' className='flex items-center gap-x-3 group'>
                <span className='text-white font-semibold group-hover:text-primary '>
                    {t('Birlikte Çalışalım')}
                </span>
                <span>
                    <svg
                        width='8'
                        height='13'
                        viewBox='0 0 8 13'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        className='group-hover:text-primary text-white'
                    >
                        <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M7.40001 6.50001C7.40001 6.76523 7.29465 7.01958 7.10712 7.20712L2.30712 12.0071C1.91659 12.3976 1.28343 12.3976 0.892902 12.0071C0.502378 11.6166 0.502378 10.9834 0.892902 10.5929L4.9858 6.50001L0.892903 2.40712C0.502378 2.01659 0.502378 1.38343 0.892903 0.992905C1.28343 0.602381 1.91659 0.602381 2.30712 0.992905L7.10712 5.79291C7.29465 5.98044 7.40001 6.2348 7.40001 6.50001Z'
                            fill='currentColor'
                        />
                    </svg>
                </span>
            </Link>
        </section>
    );
}
