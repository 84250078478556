import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    IoLogoLinkedin,
    IoLogoFacebook,
    IoLogoInstagram,
    IoLogoYoutube,
} from 'react-icons/io';
import { US, TR } from 'country-flag-icons/react/3x2';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
};

function Footer() {
    const { t, i18n } = useTranslation();
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        return null;
    };
    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('SamplePDF.pdf').then((response) => {
            response.blob().then((blob) => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href =
                    'https://drive.google.com/file/d/15mmILZJ9zkMjLZQu8gy2ZYUwRiuCiEsM/view?usp=sharing';
                alink.download = 'SamplePDF.pdf';
                alink.click();
            });
        });
    };
    return (
        <>
            <footer className='bg-black'>
                <div className='container py-20 px-14'>
                    <div className='w-full flex flex-col gap-4 divide-y divide-secondary-200'>
                        {/* Nav item */}
                        <div className='flex flex-col justify-between md:flex-row md:gap-8'>
                            <div className='pt-5 md:pt-0'>
                                <h3 className='text-white font-bold mb-4 text-center md:text-left'>
                                    {t('NavFooter')}
                                </h3>
                                <ul className='text-secondary-400 text-center md:text-left'>
                                    <Link to='/' className='hover:text-primary'>
                                        <li>{t('AnaSayfa')}</li>
                                    </Link>
                                    <Link
                                        to='/kurumsal'
                                        className='hover:text-primary'
                                    >
                                        <li>{t('Kurumsal')}</li>
                                    </Link>
                                    <Link
                                        to='/projelerimiz'
                                        className='hover:text-primary'
                                    >
                                        <li>{t('Projelerimiz')}</li>
                                    </Link>
                                    <Link
                                        to='/referanslarimiz'
                                        className='hover:text-primary'
                                    >
                                        <li>{t('Referanslarımız')}</li>
                                    </Link>
                                </ul>
                            </div>
                            <div className='pt-5 md:pt-0'>
                                <h3 className='text-white font-bold mb-4 text-center md:text-left'>
                                    {t('HizmetlerimizFooter')}
                                </h3>
                                <ul className='text-secondary-400 text-center md:text-left'>
                                    <Link
                                        to='/muhendislik-hizmetleri'
                                        className='hover:text-primary'
                                    >
                                        <li>{t('MühendislikHizmetleri')}</li>
                                    </Link>
                                    <Link
                                        to='/endustriyel-hizmetler'
                                        className='hover:text-primary'
                                    >
                                        <li>{t('EndüstriyelHizmetler')}</li>
                                    </Link>
                                    <Link
                                        to='/imalatlar'
                                        className='hover:text-primary'
                                    >
                                        <li>{t('İmalatlar')}</li>
                                    </Link>
                                </ul>
                            </div>
                            <div className='pt-5 md:pt-0'>
                                <h3 className='text-white font-bold mb-4 text-center md:text-left'>
                                    {t('BizeDairFooter')}
                                </h3>
                                <ul className='text-secondary-400 text-center md:text-left'>
                                    <Link
                                        to='/kariyer'
                                        className='hover:text-primary'
                                    >
                                        <li>{t('EkibimizeKatıl')}</li>
                                    </Link>
                                    <a
                                        href='InsMecheKatalog.pdf'
                                        download='InsMecheKatalog.pdf'
                                    >
                                        <button className='hover:text-primary'>
                                            <li>{t('EKatalog')}</li>
                                        </button>
                                    </a>
                                </ul>
                            </div>
                            <div className='pt-5 md:pt-0'>
                                <h3 className='text-white font-bold mb-4 text-center md:text-left'>
                                    {t('İletişimFooter')}
                                </h3>
                                <ul className='text-secondary-400 text-center md:text-left'>
                                    <a
                                        href='https://goo.gl/maps/SisQx9oWNnGBW9hE9'
                                        className='hover:text-primary'
                                        target='_blank'
                                    >
                                        <li>{t('Adres')}</li>
                                    </a>
                                    <a
                                        href='tel:+902244413002'
                                        className='hover:text-primary'
                                        target='_blank'
                                    >
                                        <li>+90 (224) 441 30 02</li>
                                    </a>
                                    <a
                                        href='tel:+905434463002'
                                        className='hover:text-primary'
                                        target='_blank'
                                    >
                                        <li>+90 (543) 446 30 02</li>
                                    </a>
                                    <a
                                        href='mailto:info@insmech.com'
                                        className='hover:text-primary'
                                        target='_blank'
                                    >
                                        <li>{t('MailGönder')}</li>
                                    </a>
                                    <Link
                                        to='/iletisim'
                                        className='hover:text-primary'
                                    >
                                        <li>{t('TeklifAlİ')}</li>
                                    </Link>
                                </ul>
                            </div>
                        </div>
                        {/* Language Options */}
                        <div className='flex justify-center md:justify-start gap-4 pt-4'>
                            <button
                                onClick={() => {
                                    changeLanguage('tr');
                                    handleScrollToTop();
                                }}
                            >
                                <div className='flex flex-col items-center gap-2 group'>
                                    <TR
                                        title='Türkiye Cumhuriyeti'
                                        className='w-8'
                                    />
                                    <span className='text-secondary-400 group-hover:text-primary'>
                                        {t('Türkçe')}
                                    </span>
                                </div>
                            </button>
                            <button
                                onClick={() => {
                                    changeLanguage('en');
                                    handleScrollToTop();
                                }}
                                type='button'
                            >
                                <div className='flex flex-col items-center gap-2 group'>
                                    <US title='United States' className='w-8' />
                                    <span className='text-secondary-400 group-hover:text-primary'>
                                        {t('İngilizce')}
                                    </span>
                                </div>
                            </button>
                        </div>
                        {/* Footer Subtitle */}
                        <div className='flex flex-col md:flex-row justify-between items-center gap-y-4 md:gap-y-0 pt-4'>
                            <div className='text-secondary-400 text-sm flex flex-col items-center md:items-start'>
                                <p>
                                    {t('CreatedBy')}
                                    <span className='text-white font-bold'>
                                        Enes Bakadur{t('Nokta')}
                                    </span>{' '}
                                    {t('Tarafından')}
                                </p>
                                <p>{t('Copyright')}</p>
                            </div>
                            <div className='flex gap-x-4'>
                                <a
                                    href='https://tr.linkedin.com/company/i%CC%87nsmech-m%C3%BChendi%CC%87sli%CC%87k'
                                    className='group'
                                    target='_blank'
                                >
                                    <IoLogoLinkedin className='fill-secondary-400 w-6 h-6 group-hover:fill-primary' />
                                </a>
                                <a
                                    href='https://www.facebook.com/insmechmuhendislik/'
                                    className='group'
                                    target='_blank'
                                >
                                    <IoLogoFacebook className='fill-secondary-400 w-6 h-6 group-hover:fill-primary' />
                                </a>
                                <a
                                    href='https://www.instagram.com/insmechmuhendislik/'
                                    className='group'
                                    target='_blank'
                                >
                                    <IoLogoInstagram className='fill-secondary-400 w-6 h-6 group-hover:fill-primary' />
                                </a>
                                <a
                                    href='https://www.youtube.com/channel/UCVTyZkH8V4AeAd30U1bUIeQ'
                                    className='group'
                                    target='_blank'
                                >
                                    <IoLogoYoutube className='fill-secondary-400 w-6 h-6 group-hover:fill-primary' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
