import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './screens/Home';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import Contact from './screens/Contact';
import Career from './screens/Career';
import React, { useEffect } from 'react';
import OurProjects from './screens/OurProjects';
import EngineeringServices from './components/OurServices/EngineeringServices';
import IndustrialServices from './components/OurServices/IndustrialServices';
import Manufacturing from './components/OurServices/Manufacturing';
import References from './screens/References';
import Corporate from './screens/Corporate';
import Tagrak from './components/OurProjects/Tagrak';
import SepetTasima from './components/OurProjects/SepetTasima';
import Slat from './components/OurProjects/Slat';

function App() {
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    };
    function ScrollToTop() {
        const { pathname } = useLocation();

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);

        return null;
    }

    return (
        <React.StrictMode>
            <Navbar />
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<Home />}></Route>
                <Route path='/kurumsal' element={<Corporate />}></Route>
                <Route path='/iletisim' element={<Contact />}></Route>
                <Route path='/kariyer' element={<Career />}></Route>
                <Route path='/projelerimiz' element={<OurProjects />}></Route>
                <Route
                    path='/tagrak-shuttle-skid-aktarma'
                    element={<Tagrak />}
                ></Route>
                <Route path='/sepet-tasima' element={<SepetTasima />}></Route>
                <Route path='/slat' element={<Slat />}></Route>
                <Route
                    path='/muhendislik-hizmetleri'
                    element={<EngineeringServices />}
                ></Route>
                <Route
                    path='/endustriyel-hizmetler'
                    element={<IndustrialServices />}
                ></Route>
                <Route path='/imalatlar' element={<Manufacturing />}></Route>
                <Route path='/referanslarimiz' element={<References />}></Route>
            </Routes>
            <Footer />
        </React.StrictMode>
    );
}

export default App;
