import React from 'react';
import { useTranslation } from 'react-i18next';

export default function MissionAndVisionCard(props) {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className='bg-secondary-100 p-6 col-span-2 lg:col-span-1 border border-secondary-200 rounded-[20px] flex flex-col items-center gap-y-3 hover:bg-secondary transition-all duration-500 delay-100'>
                <div className='flex items-center mb-4 h-12'>
                    <img src={props.icon} className='w-12' alt='card-icon' />
                </div>
                <div>
                    <h2 className='text-tertiary font-bold mb-2'>
                        {t(`${props.title}`)}
                    </h2>
                    <p className='text-secondary-400 wb-break-words'>
                        {t(`${props.description}`)}
                    </p>
                </div>
            </div>
        </>
    );
}
