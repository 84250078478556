import React from 'react';
import { InputText } from 'primereact/inputtext';

export default function BasicInput(props) {
    return (
        <>
            <label for={props.for} className='text-white font-semibold'>
                {props.label}
            </label>
            <InputText
                id={props.id}
                placeholder={props.placeholder}
                name={props.name}
                className='mt-2 placeholder-secondary-300 text-tertiary px-2 py-4 w-full block bg-secondary focus:bg-secondary-200 border border-secondary-200 hover:border-primary/60 focus:border-primary active:border-primary rounded-md  focus:outline-none'
            />
        </>
    );
}
